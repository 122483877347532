import React, { FunctionComponent } from 'react'
import {
  FoldOut,
  ProductInfo,
  ProductInfoSummary,
  FloatingBar,
} from '@vwfs-bronson/bronson-react'
import { ShoppingCartModel } from './ShoppingCartModel'
import { buildVin } from './common'
import { useAriaLabel } from '../../hooks/useAriaLabel'

const scrollRef: any = React.createRef()

const ShoppingCart: FunctionComponent<ShoppingCartModel> = ({
  id,
  defaultOpen = false,
  floatingBar,
  imgSrc,
  imgSrcSet,
  imgSizes,
  title,
  titleSuffix,
  subtitle,
  infoLabel,
  infoValue,
  children,
  subtitleImg,
  vin,
  labelHidden,
}: ShoppingCartModel) => {
  const { ariaLabel, setAriaLabelStatus } = useAriaLabel(defaultOpen)
  
  return (
    <>
      <div ref={scrollRef}></div>
      {<FoldOut
        labelHidden={labelHidden}
        id={id}
        fullToggle
        onToggle={setAriaLabelStatus}
        aria-label={ariaLabel}
        defaultOpen={defaultOpen}
        productInfoSummary={
          <ProductInfoSummary
            imgSrc={imgSrc}
            imgSrcSet={imgSrcSet}
            imgSizes={imgSizes}
            imgAlt="Fahrzeugbild"
            title={title}
            subtitle={subtitle}
            infoLabel={infoLabel}
            infoValue={infoValue}
          />
        }
      >
        {buildVin(vin || '')}
        <ProductInfo
          title={title}
          titleSuffix={titleSuffix}
          media={<img src={imgSrc} alt="Fahrzeugbild" />}
          caption={subtitleImg}
        >
          {children}
        </ProductInfo>
      </FoldOut>}
      {floatingBar ? (
        <FloatingBar target={scrollRef}>
          <ProductInfoSummary
            imgSrc={imgSrc}
            imgAlt={title}
            title={title}
            subtitle={subtitle}
            infoLabel={infoLabel}
            infoValue={infoValue}
          />
        </FloatingBar>
      ) : null}
    </>
  )
}

export { ShoppingCart }

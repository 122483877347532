import { Modal, Paragraph, Spinner } from '@vwfs-bronson/bronson-react'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  confirmationRefuse,
  getConfirmationData,
} from '../../../services/api/confirmation'
import { store } from '../../../services/redux'
import * as actions from '../../../services/redux/features/storefront.redux'
import * as customActions from '../../../services/redux/features/custom.redux'
import routes from '../../../routes'
import { isCorrectEntryState } from '../transactions.utils'

const TransactionsRefusalPage: FunctionComponent = () => {
  const { id, acceptanceId } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storefrontData = useSelector(actions.getStorefrontData)

  const [isLoading, setIsloading] = useState(false)
  const [isReady, setIsReady] = useState(false)

  const getData = async () => {
    if (!id) {
      dispatch(customActions.setErrorData({ url: window.location.href }));
      navigate(routes.errorPage)
      return;
    }

    setIsReady(false)

    try {
      const data = await getConfirmationData(id)
      if (!isCorrectEntryState(data)) {
        return navigate(routes.transactionsError)
      }
      store.dispatch(actions.storeId(id))
      store.dispatch(actions.initial(data))
    } catch {
      dispatch(customActions.setErrorData({ url: window.location.href }));
      navigate(routes.errorPage)
    } finally {
      setIsReady(true)
    }
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const getOriginSystem = () => storefrontData?.salesChannel?.originSystem

  const onConfirm = async () => {
    if (!id || !acceptanceId) {
      dispatch(customActions.setErrorData({ url: window.location.href }));
      navigate(routes.errorPage)
      return;
    }

    setIsloading(true)

    try {
      await confirmationRefuse(id, acceptanceId)
      navigate(routes.transactionsCanceled)
    } catch {
      dispatch(customActions.setErrorData({ url: window.location.href }));
      navigate(routes.errorPage)
    } finally {
      setIsloading(false)
    }
  }

  return (
    <>
      {(isLoading || !isReady) && <Spinner center fullPage />}
      {isReady && (
        <Modal
          center
          title={t(`transactions:${getOriginSystem()}:refusal:title`, {
            defaultValue: t(`transactions:default:refusal:title`),
          })}
          shown={true}
          hideCloseButton
          buttonConfirmText={t(
            `transactions:${getOriginSystem()}:refusal:confirmButton`,
            {
              defaultValue: t(`transactions:default:refusal:confirmButton`),
            }
          )}
          onConfirm={onConfirm}
        >
          <Paragraph className="u-pt u-pb">
            {t(`transactions:${getOriginSystem()}:refusal:subtitle`, {
              defaultValue: t(`transactions:default:refusal:subtitle`),
            })}
          </Paragraph>
        </Modal>
      )}
    </>
  )
}

export default TransactionsRefusalPage

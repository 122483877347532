import axios from 'axios'
import { store } from '../../redux'
import * as actions from '../../redux/features/storefront.redux'
import * as sessionActions from '../../redux/features/session.redux'
import * as customActions from '../../redux/features/custom.redux'
import routes from '../../../routes'
import { isDealershop, isVtiGiS } from '../../common/utils'
import { NavigateFunction } from 'react-router-dom';

const { REACT_APP_API_GATEWAY } = process.env

export const getStorefrontData = async (storefrontId: string) => {
  const url = `${REACT_APP_API_GATEWAY}/data/${storefrontId}`

  return axios.get(url, { validateStatus: (statusCode) => statusCode === 200 })
}

export const loadStorefrontDataToRedux = async (cid: string, navigate: NavigateFunction) => {
  store.dispatch(actions.storeId(cid))
  return internalLoadStorefrontDataToRedux(cid, navigate)
}

const internalLoadStorefrontDataToRedux = async (cid: string, navigate: NavigateFunction) => {
  store.dispatch(actions.pending())

  try {
    const response = await getStorefrontData(cid)
    const data = response.data

    store.dispatch(sessionActions.setTokenExpiresAt(data?.tokenExpiresAt));
    if (isVtiGiS(data) || isDealershop(data)) {
      store.dispatch(
        customActions.setServiceAndInspection(!!data?.custom?.serviceAndInspection)
      )
    }
    return store.dispatch(actions.initial(data))

  } catch (error: any) {
    if (error?.response?.status === 409) {
      store.dispatch(
        actions.setRedirectUrl(error?.response?.data?.payload?.redirectUrl)
      )
      navigate(routes.vehicleNotAvailablePage)
    } else {
      store.dispatch(customActions.setErrorData({ url: window.location.href }));
      navigate(routes.errorPage)
    }
  }
}

import React, { FunctionComponent, PropsWithChildren } from 'react'
import { useNavigate } from 'react-router-dom'

export const HtmlContent: FunctionComponent<PropsWithChildren> = ({
  children,
  ...props
}) => {
  const navigate = useNavigate();
  
  const handleClick = (e) => {
    const href = e.target.getAttribute('href')
    if (href && href.startsWith('/')) {
      e.preventDefault()
      navigate(href)
    }
  }
  return (
    <span
      {...props}
      onClick={handleClick}
      dangerouslySetInnerHTML={{ __html: children as any }}
    />
  )
}

import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Formik, Form, FastField } from 'formik'
import {
  Fieldset,
  Card,
  Paragraph,
  Heading,
} from '@vwfs-bronson/bronson-react'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import * as Yup from 'yup'
import { CheckboxField } from '../../../components/CheckboxField'
import { useSelector } from 'react-redux'
import { getStorefrontData } from '../../../services/redux/features/storefront.redux'

interface Props {
  hasSubmited: boolean,
  setVehicleConditionValues: (param) => void,
  setIsDirty: (param: boolean) => void,
}

const VehicleConditionDealerShop = forwardRef(({
  hasSubmited,
  setVehicleConditionValues,
  setIsDirty: setIsDirtyParent,
}: Props, ref) => {
  const { t } = useTranslation()
  const storefrontData = useSelector(getStorefrontData)
  const [isDirty, setIsDirty] = useState(false)
  const formState = useRef(undefined as any)
  const submitFunctionRef = useRef<() => void>()

  useImperativeHandle(ref, () => ({
    submit: () => submitFunctionRef.current?.(),
    isValid: () => formState.current.isValid,
    validate: () => formState.current.isValid,
  }))

  useEffect(() => {
    setIsDirtyParent(isDirty)
  }, [isDirty, setIsDirtyParent])

  useEffect(() => {
    if (hasSubmited) {
      setTouchedForm(formState.current)
    }
  }, [hasSubmited])

  const onChangeForm = async (e, formProps) => {
    const { name, type, checked, value } = e.target;
    if (name) {
      const newValue = type === 'checkbox' ? checked : value
      await formProps.setFieldValue(name, newValue)
      await formProps.setFieldTouched(name)
      await setVehicleConditionValues({ ...formProps.values, [name]: newValue })
    }
  }
  
  const setTouchedForm = (formProps) => {
    Object.keys(formProps.values).forEach((field) => {
      formProps.setFieldTouched(field)
    })
  }

  const ValidationSchema = Yup.object().shape({
    acceptVehicleDefects: Yup.boolean()
      .required(i18n.t('validation:required'))
      .oneOf([true], i18n.t('validation:required')),
    acceptUpdateObligation: Yup.boolean()
      .required(i18n.t('validation:required'))
      .oneOf([true], i18n.t('validation:required')),
  })

  return (
    <Formik
      innerRef={formState}
      initialValues={{
        acceptVehicleDefects: false,
        acceptUpdateObligation: false,
      }}
      validationSchema={ValidationSchema}
      validateOnMount
      onSubmit={() => {}}
    >
      {(formProps) => {
        setIsDirty(formProps.dirty)
        submitFunctionRef.current = formProps.submitForm

        return (
          <Form
            id="vehicle-condition-form"
            onChange={(e) => onChangeForm(e, formProps)}
          >
            <Card element='article' className="u-text-left">
              <Heading level={4}>{t('form:vehicleCondition:title')}</Heading>
              <Paragraph>
                {t('form:vehicleCondition:DEALERSHOP:deviationDescription')}
                <br />
                <span>
                  <strong>
                    {storefrontData?.vehicleData?.deviationDescription}
                  </strong>
                </span>
              </Paragraph>
              {storefrontData?.vehicleData?.damageDescription && (
                <Paragraph>
                  {t('form:vehicleCondition:DEALERSHOP:damageDescription')}
                  <br />
                  <span>
                    <strong>
                      {storefrontData?.vehicleData?.damageDescription}
                    </strong>
                  </span>
                </Paragraph>
              )}

              <Fieldset className="u-mt">
                <Fieldset.Row>
                  <FastField
                    name="acceptVehicleDefects"
                    label={t(
                      'form:vehicleCondition:DEALERSHOP:acceptVehicleDefects',
                      { companyName: storefrontData?.dealerData?.companyName }
                    )}
                    component={CheckboxField}
                  />
                </Fieldset.Row>
                <Fieldset.Row>
                  <FastField
                    name="acceptUpdateObligation"
                    label={t(
                      'form:vehicleCondition:DEALERSHOP:acceptUpdateObligation',
                      { companyName: storefrontData?.dealerData?.companyName }
                    )}
                    component={CheckboxField}
                  />
                </Fieldset.Row>
              </Fieldset>
            </Card>
          </Form>
        )
      }}
    </Formik>
  )
})

export default VehicleConditionDealerShop

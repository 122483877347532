/* eslint-disable no-negated-condition */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useMemo } from 'react'
import {
  Card,
  ConsumptionGraphic,
  ContentSection,
  DescriptionList,
  EfficiencyTag,
  Layout,
  Modal,
  Paragraph,
} from '@vwfs-bronson/bronson-react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { EnergyManagementType } from '../../models/enums'
import { Output } from '../../Text'

const FONT_SIZE_CLASS = 'u-font-size-fs-1'

export type DescriptionListGroupProps = {
  onClose: any
  shown: boolean
  vehicleData: any
}

const ENVKVShoppingCartCaptionModal: FunctionComponent<
  DescriptionListGroupProps
> = (props) => {
  const { t } = useTranslation()
  const { onClose, shown = false, vehicleData } = props
  const economics = useMemo(
    () => vehicleData?.model?.economics?.[0],
    [vehicleData]
  )

  const engineType = useMemo(() => economics?.engineType, [economics])

  const activeRatings = useMemo(
    () =>
      economics?.emissions?.map((emission) =>
        emission?.CO2Class?.toLowerCase()
      ),
    [economics]
  )
  const activeLabels = useMemo(
    () =>
      activeRatings?.length > 1
        ? ['gewichtet, kombiniert', 'bei entladener Batterie']
        : [],
    [activeRatings]
  )

  const getSpecificConsumptions = (energyManagementType) =>
    economics?.consumptions?.find(
      (consumption) =>
        consumption?.energyManagementType === energyManagementType
    ) || [EnergyManagementType.UNESPECIFIED]

  const templateValues = useMemo(
    () => ({
      manufacturer: vehicleData?.model?.manufacturer,
      engineType: t(
        'form:shoppingCart.imageFooter.ENVKV.modal.engineTypeMap'
      )?.[vehicleData?.model?.engine?.engineType],
      fuelType:
        t('form:shoppingCart.imageFooter.ENVKV.modal.fuelTypeMap')?.[
          vehicleData?.model?.engine?.fuelType
        ] || t('form:shoppingCart.imageFooter.ENVKV.modal.fuelTypeMap')['NONE'],
      description: vehicleData?.model?.description,
      otherFuelType:
        t(
          'pages.oneShop.shoppingCart.imageFooter.ENVKV.modal.otherFuelTypeMap'
        )?.[vehicleData?.model?.engine?.otherFuelType] ||
        t('form:shoppingCart.imageFooter.ENVKV.modal.otherFuelTypeMap')[
          'default'
        ],
      consumptions: `${economics?.consumptions?.[0]?.combined?.value} ${economics?.consumptions?.[0]?.combined?.unit}`,
      emissions: `${economics?.emissions?.[0]?.combined?.value} ${economics?.emissions?.[0]?.combined?.unit}`,
      ranges: `${economics?.ranges?.[0]?.combined?.value} ${economics?.ranges?.[0]?.combined?.unit}`,
      combined: `${economics?.consumptions?.[0]?.combined?.value} ${economics?.consumptions?.[0]?.combined?.unit}`,
      low: `${economics?.consumptions?.[0]?.low?.value} ${economics?.consumptions?.[0]?.low?.unit}`,
      medium: `${economics?.consumptions?.[0]?.medium?.value} ${economics?.consumptions?.[0]?.medium?.unit}`,
      high: `${economics?.consumptions?.[0]?.high?.value} ${economics?.consumptions?.[0]?.high?.unit}`,
      extraHigh: `${economics?.consumptions?.[0]?.extraHigh?.value} ${economics?.consumptions?.[0]?.extraHigh?.unit}`,
      combinedPURE: `${
        getSpecificConsumptions(EnergyManagementType.PURE)?.combined?.value
      } ${getSpecificConsumptions(EnergyManagementType.PURE)?.combined?.unit}`,
      lowPURE: `${
        getSpecificConsumptions(EnergyManagementType.PURE)?.low?.value
      } ${getSpecificConsumptions(EnergyManagementType.PURE)?.low?.unit}`,
      mediumPURE: `${
        getSpecificConsumptions(EnergyManagementType.PURE)?.medium?.value
      } ${getSpecificConsumptions(EnergyManagementType.PURE)?.medium?.unit}`,
      highPURE: `${
        getSpecificConsumptions(EnergyManagementType.PURE)?.high?.value
      } ${getSpecificConsumptions(EnergyManagementType.PURE)?.high?.unit}`,
      extraHighPURE: `${
        getSpecificConsumptions(EnergyManagementType.PURE)?.extraHigh?.value
      } ${getSpecificConsumptions(EnergyManagementType.PURE)?.extraHigh?.unit}`,
      combinedSUSTAINING: `${
        getSpecificConsumptions(EnergyManagementType.SUSTAINING)?.combined
          ?.value
      } ${
        getSpecificConsumptions(EnergyManagementType.SUSTAINING)?.combined?.unit
      }`,
      lowSUSTAINING: `${
        getSpecificConsumptions(EnergyManagementType.SUSTAINING)?.low?.value
      } ${getSpecificConsumptions(EnergyManagementType.SUSTAINING)?.low?.unit}`,
      mediumSUSTAINING: `${
        getSpecificConsumptions(EnergyManagementType.SUSTAINING)?.medium?.value
      } ${
        getSpecificConsumptions(EnergyManagementType.SUSTAINING)?.medium?.unit
      }`,
      highSUSTAINING: `${
        getSpecificConsumptions(EnergyManagementType.SUSTAINING)?.high?.value
      } ${
        getSpecificConsumptions(EnergyManagementType.SUSTAINING)?.high?.unit
      }`,
      extraHighSUSTAINING: `${
        getSpecificConsumptions(EnergyManagementType.SUSTAINING)?.extraHigh
          ?.value
      } ${
        getSpecificConsumptions(EnergyManagementType.SUSTAINING)?.extraHigh
          ?.unit
      }`,
      fuelExpenseAmount: economics?.fuelExpenseAmount?.grossAmount,
      fuelPriceAmount: economics?.fuelPriceAmount?.grossAmount,
      electricityPriceAmount: economics?.electricityPriceAmount?.grossAmount,
      fuelPriceCaclYear: economics?.fuelPriceCaclYear?.grossAmount,
      mediumAverageCO2Price:
        economics?.emissionCosts?.[0]?.mediumAverageCO2Price,
      mediumValueAmount: `${economics?.emissionCosts?.[0]?.mediumValueAmount?.grossAmount} ${economics?.emissionCosts?.[0]?.mediumValueAmount?.currency}`,
      lowAverageCO2Price: economics?.emissionCosts?.[0]?.lowAverageCO2Price,
      lowValueAmount: `${economics?.emissionCosts?.[0]?.lowValueAmount?.grossAmount} ${economics?.emissionCosts?.[0]?.lowValueAmount?.currency}`,
      highAverageCO2Price: economics?.emissionCosts?.[0]?.highAverageCO2Price,
      highValueAmount: `${economics?.emissionCosts?.[0]?.highValueAmount?.grossAmount} ${economics?.emissionCosts?.[0]?.highValueAmount?.currency}`,
      motorVehicleTaxAmount: economics?.motorVehicleTaxAmount?.grossAmount,
      startDate:
        economics?.emissionCosts?.[0]?.period?.startDate &&
        moment(economics?.emissionCosts?.[0]?.period?.startDate).format(
          'DD.MM.YYYY'
        ),
      endDate:
        economics?.emissionCosts?.[0]?.period?.endDate &&
        moment(economics?.emissionCosts?.[0]?.period?.endDate).format(
          'DD.MM.YYYY'
        ),
      vin: vehicleData?.vin,
      currentDate: moment().format('DD.MM.YYYY'),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [economics, vehicleData]
  )

  return (
    <Modal
      large
      stretch
      shown={shown}
      title={t(
        `form:shoppingCart.imageFooter.ENVKV.modal.${engineType}.title`,
        {
          defaultValue: t(
            `form:shoppingCart.imageFooter.ENVKV.modal.default.title`
          ),
        }
      )}
      onClose={onClose}
      className={FONT_SIZE_CLASS}
    >
      <Card element='article' className="u-mv-small">
        <DescriptionList horizontal shortTerms grid>
          {Object.entries(
            t(`form:shoppingCart.imageFooter.ENVKV.modal.${engineType}.box1`, {
              defaultValue: t(
                'form:shoppingCart.imageFooter.ENVKV.modal.default.box1',
                { returnObjects: true }
              ),
              returnObjects: true,
            })
          )?.map(([key, text]: any) => (
            <DescriptionList.Group
              key={key}
              termText={<span className={FONT_SIZE_CLASS}>{text}</span>}
            >
              <DescriptionList.Detail className={FONT_SIZE_CLASS}>
                {templateValues?.[key]}
              </DescriptionList.Detail>
            </DescriptionList.Group>
          ))}
        </DescriptionList>
      </Card>
      <Card element='article' className="u-mv-small">
        <DescriptionList horizontal shortTerms>
          {Object.entries(
            t(`form:shoppingCart.imageFooter.ENVKV.modal.${engineType}.box2`, {
              defaultValue: t(
                'form:shoppingCart.imageFooter.ENVKV.modal.default.box2',
                { returnObjects: true }
              ),
              returnObjects: true,
            })
          )?.map(([key, text]: any) => (
            <DescriptionList.Group
              key={key}
              termText={<span className={FONT_SIZE_CLASS}>{text}</span>}
            >
              <DescriptionList.Detail className={FONT_SIZE_CLASS}>
                {templateValues?.[key]}
              </DescriptionList.Detail>
            </DescriptionList.Group>
          ))}
        </DescriptionList>
      </Card>
      <Layout equalHeight>
        <Layout.Item default="3/5" s="1/1">
          <Card element='article' className="u-text-left">
            <Paragraph>
              <strong>
                {t(
                  `form:shoppingCart.imageFooter.ENVKV.modal.${engineType}.efficiencyClass.title`,
                  {
                    defaultValue: t(
                      'form:shoppingCart.imageFooter.ENVKV.modal.default.efficiencyClass.title'
                    ),
                  }
                )}
              </strong>
            </Paragraph>
            <Paragraph>
              {t(
                `form:shoppingCart.imageFooter.ENVKV.modal.${engineType}.efficiencyClass.subtitle`,
                {
                  defaultValue: t(
                    'form:shoppingCart.imageFooter.ENVKV.modal.default.efficiencyClass.subtitle'
                  ),
                }
              )}
            </Paragraph>
            <ConsumptionGraphic
              activeRatings={activeRatings}
              labels={activeLabels}
              scope="de"
            >
              <EfficiencyTag rating="a" />
              <EfficiencyTag rating="b" />
              <EfficiencyTag rating="c" />
              <EfficiencyTag rating="d" />
              <EfficiencyTag rating="e" />
              <EfficiencyTag rating="f" />
              <EfficiencyTag rating="g" />
            </ConsumptionGraphic>
          </Card>
        </Layout.Item>
        <Layout.Item default="2/5" s="1/1">
          <Card element='article' className="u-text-left">
            <Paragraph className="u-mb-small">
              <strong>
                {t(
                  `form:shoppingCart.imageFooter.ENVKV.modal.${engineType}.efficiencyClass.moreInfo.title`,
                  {
                    defaultValue: t(
                      'form:shoppingCart.imageFooter.ENVKV.modal.default.efficiencyClass.moreInfo.title'
                    ),
                  }
                )}
              </strong>
            </Paragraph>
            {(
              t(
                `form:shoppingCart.imageFooter.ENVKV.modal.${engineType}.efficiencyClass.moreInfo.blocks`,
                {
                  defaultValue: t(
                    'form:shoppingCart.imageFooter.ENVKV.modal.default.efficiencyClass.moreInfo.blocks',
                    { returnObjects: true }
                  ),
                  returnObjects: true,
                }
              ) as any
            )?.map((block, index) => {
              return getSpecificConsumptions(block?.key) ? (
                <div key={block?.title} className={index !== 0 ? 'u-mt' : ''}>
                  <DescriptionList split>
                    {Object.entries(block?.values).map(
                      ([key, text]: any, i: any) => (
                        <DescriptionList.Group
                          key={key}
                          termText={
                            <span className={FONT_SIZE_CLASS}>
                              {!i ? <strong>{text}</strong> : text}
                            </span>
                          }
                        >
                          <DescriptionList.Detail className={FONT_SIZE_CLASS}>
                            {templateValues?.[key]}
                          </DescriptionList.Detail>
                        </DescriptionList.Group>
                      )
                    )}
                  </DescriptionList>
                </div>
              ) : (
                <></>
              )
            })}
          </Card>
        </Layout.Item>
      </Layout>
      <Card element='article' className="u-mv-small u-text-left">
        <DescriptionList split>
          {Object.entries(
            t(
              `form:shoppingCart.imageFooter.ENVKV.modal.${engineType}.specificBox`,
              {
                defaultValue: t(
                  'form:shoppingCart.imageFooter.ENVKV.modal.default.specificBox',
                  { returnObjects: true, ...templateValues }
                ),
                returnObjects: true,
                ...templateValues,
              }
            )
          )?.map(([key, text]: any) => (
            <DescriptionList.Group
              key={text}
              termText={
                <div
                  className={FONT_SIZE_CLASS}
                  dangerouslySetInnerHTML={{
                    __html: text,
                  }}
                />
              }
            >
              <DescriptionList.Detail className={FONT_SIZE_CLASS}>
                {t(
                  `form:shoppingCart.imageFooter.ENVKV.modal.${engineType}.specificBoxValues`,
                  {
                    defaultValue: t(
                      'form:shoppingCart.imageFooter.ENVKV.modal.default.specificBoxValues',
                      { returnObjects: true, ...templateValues }
                    ),
                    returnObjects: true,
                    ...templateValues,
                  }
                )?.[key] || ''}
              </DescriptionList.Detail>
            </DescriptionList.Group>
          ))}
        </DescriptionList>
      </Card>
      <Card element='article' className="u-mv-small u-text-left">
        {(
          t(`form:shoppingCart.imageFooter.ENVKV.modal.${engineType}.textBox`, {
            defaultValue: t(
              'form:shoppingCart.imageFooter.ENVKV.modal.default.textBox',
              { returnObjects: true, ...templateValues }
            ),
            returnObjects: true,
            ...templateValues,
          }) as any
        )?.map((text) => (
          <Paragraph
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        ))}
      </Card>
      <ContentSection>
        <div>
          {Output.markdown(
            t(
              `form:shoppingCart.imageFooter.ENVKV.modal.${engineType}.suffix.vin`,
              {
                defaultValue: t(
                  'form:shoppingCart.imageFooter.ENVKV.modal.default.suffix.vin',
                  { ...templateValues }
                ),
                ...templateValues,
              }
            )
          )}
          {Output.markdown(
            t(
              `form:shoppingCart.imageFooter.ENVKV.modal.${engineType}.suffix.currentDate`,
              {
                defaultValue: t(
                  'form:shoppingCart.imageFooter.ENVKV.modal.default.suffix.currentDate',
                  { ...templateValues }
                ),
                ...templateValues,
              }
            )
          )}
        </div>
        <Paragraph
          dangerouslySetInnerHTML={{
            __html: t(
              `form:shoppingCart.imageFooter.ENVKV.modal.${engineType}.suffix.text`,
              {
                defaultValue: t(
                  'form:shoppingCart.imageFooter.ENVKV.modal.default.suffix.text'
                ),
              }
            ),
          }}
        />
      </ContentSection>
    </Modal>
  )
}

export default ENVKVShoppingCartCaptionModal

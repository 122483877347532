import React, { useMemo } from 'react'
import {
  ContentSection,
  Fieldset,
  Paragraph,
} from '@vwfs-bronson/bronson-react'
import {
  getOriginSystem,
  isDealershop,
  isVaFa,
} from '../../../services/common/utils'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getStorefrontData } from '../../../services/redux/features/storefront.redux'

const SummaryVehicleSectionSuffix = () => {
  const { t } = useTranslation()
  const storefrontData = useSelector(getStorefrontData)
  const originSystem = useMemo(() => getOriginSystem(storefrontData), [storefrontData])

  return (
    <>
      {isDealershop(storefrontData) && (
        <ContentSection.ComponentWrapper className="u-mt">
          <Paragraph>
            {t(`summaryVehicle:${originSystem}:suffix:1`)}
          </Paragraph>
          <Paragraph>
            <span>
              {t(
                `summaryVehicle:${originSystem}:suffix:2:text1`
              )}
              <a
                href={storefrontData?.dealerData?.cancellationPolicyUrl}
                target="_blank"
                rel="noreferrer"
              >
                {t(
                  `summaryVehicle:${originSystem}:suffix:2:link:text`
                )}
              </a>
              {t(
                `summaryVehicle:${originSystem}:suffix:2:text2`
              )}
            </span>
          </Paragraph>
          <Paragraph className="u-mb-none">
            {t(
              `summaryVehicle:${originSystem}:suffix:dealer`
            )}
            <br />
            {storefrontData?.dealerData?.companyName}
            <br />
            {`${storefrontData?.dealerData?.address?.street} ${storefrontData?.dealerData?.address?.houseNumber}`}
            <br />
            {`${storefrontData?.dealerData?.address?.zipCode} ${storefrontData?.dealerData?.address?.city}`}
          </Paragraph>
        </ContentSection.ComponentWrapper>
      )}
      {isVaFa(storefrontData) && (
        <ContentSection.ComponentWrapper className="u-mt">
          <Fieldset.Row>
            <span
              dangerouslySetInnerHTML={{
                __html: t('summaryVehicle:additionalFooterNote'),
              }}
            />
          </Fieldset.Row>
        </ContentSection.ComponentWrapper>
      )}
    </>
  )
}

export default SummaryVehicleSectionSuffix

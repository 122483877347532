import React, { useCallback, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ButtonContainer, Button, Modal } from '@vwfs-bronson/bronson-react'
import { useTranslation } from 'react-i18next'

import { Analytics } from '../../services/analytics'
import routes from '../../routes'

export const NavigationBlockedModal = () => {
  const [visible, setVisible] = React.useState(false)
  const { t } = useTranslation()
  const location = useLocation();
  const navigate = useNavigate();
  const pageName = 'Browser navigation unavailable'

  const show = useCallback(() => {
    setVisible(true)
    trackPage()
  }, [])

  const hide = () => {
    setVisible(false)
  }

  const trackPage = () => {
    setTimeout(() => {
      const pageData = {
        [Analytics.keys.eventType(0)]: 'pageView',
        [Analytics.keys.eventAction(0)]: 'Success',
      }
      Analytics.trackPage({
        [Analytics.keys.pageName()]: pageName,
        [Analytics.keys.viewChange()]: pageName,
        ...pageData
      })
    }, 1000)
  }

  useEffect(() => {
    const unblock = () => {
      if (location.pathname === routes.errorPage) {
        return // Skip blocking on error page
      }

      // Send user back if they try to navigate back
      navigate(1)
      show()
    }

    window.addEventListener('popstate', unblock)

    return () => {
      window.removeEventListener('popstate', unblock)
    }
  }, [location, navigate, show])

  return (
    <Modal
      title={t('navigationBlockedModal:title')}
      onClose={hide}
      shown={visible && location.pathname !== routes.errorPage}
    >
      {t('navigationBlockedModal:text')}

      <ButtonContainer center className="u-mt">
        <Button type='button' onClick={hide}>
          {t('navigationBlockedModal:close')}
        </Button>
      </ButtonContainer>
    </Modal>
  )
}

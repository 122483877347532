import React, { useEffect, useState } from 'react'
import { Routes as RouterRoutes, Route, Navigate, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'
import { DefaultLayout } from '../Layout'
import AppForm from '../../pages/AppPage'
import ErrorPage from '../../pages/ErrorPage'
import ConfirmationPage from '../../pages/ConfirmationPage'
import routes from '../../routes'
import ThirdPartyLicensePage from '../../pages/LicensesPage/ThirdPartyLicensePage'
import VehicleNotAvailablePage from '../../pages/VehicleNotAvailablePage'
import WithStorefront from '../../services/api/storefront/with-storefront'
import { NavigationBlockedModal } from '../../components/NavigationBlockedModal/index'
import { getStorefrontData } from '../../services/redux/features/storefront.redux'
import DataProtectionInfoPage from '../../pages/DataProtectionInfoPage'
import {
  getTokenExpiresAt,
  setTokenExpiresAt as SetTokenExpiresAtAction,
} from '../../services/redux/features/session.redux'
import { ExpiredSessionModal } from '../../pages/ExpiredSession/ExpiredSessionModal'
import { expiredSessionLogic } from '../../pages/ExpiredSession/ExpiredSessionLogic'
import { ExpiredSessionPage } from '../../pages/ExpiredSession/ExpiredSessionPage'
import { saveSesionData } from '../../services/common/utils'
import TransactionsApproval from '../../pages/Transactions/TransactionsApproval'
import TransactionsConfirmed from '../../pages/Transactions/TransactionsConfirmed'
import TransactionsCanceled from '../../pages/Transactions/TransactionsCanceled'
import TransactionsRefusal from '../../pages/Transactions/TransactionsRefusal'
import TransactionsErrorPage from '../../pages/Transactions/TransactionsErrorPage'

export type RoutesProps = {
  storefrontId: string
  getPageFile: any
}

const Routes = ({
  storefrontId,
  entryUrl,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const storefrontData = useSelector(getStorefrontData);
  const tokenExpiresAt = useSelector(getTokenExpiresAt);

  const [showExpiredSessionModal, setShowExpiredSessionModal] = useState(false)

  useEffect(() => {
    if (tokenExpiresAt) {
      expiredSessionLogic(tokenExpiresAt, () => setShowExpiredSessionModal(true))
    }
  }, [tokenExpiresAt])

  return (
    <>
      <ExpiredSessionModal
        shown={showExpiredSessionModal}
        onConfirm={(data: any) => {
          setShowExpiredSessionModal(false)
          saveSesionData({
            transactionId: storefrontId,
            token: data?.data?.token,
          })
          dispatch(SetTokenExpiresAtAction(data?.data?.tokenExpiresAt))
        }}
        onClose={() => {
          setShowExpiredSessionModal(false)
          navigate(routes.expiredSession)
        }}
        transactionId={storefrontId}
      />

      <NavigationBlockedModal />
      
      <RouterRoutes>
        <Route
          path={routes.editPage}
          element={
            <DefaultLayout>
              <AppForm editPage />
            </DefaultLayout>
          }
        />
        <Route
          path={routes.errorPage}
          element={
            <DefaultLayout>
              <ErrorPage entryUrl={entryUrl} />
            </DefaultLayout>
          }
        />
        <Route
          path={routes.vehicleNotAvailablePage}
          element={
            <DefaultLayout>
              <VehicleNotAvailablePage />
            </DefaultLayout>
          }
        />
        <Route
          path={routes.expiredSession}
          element={
            <DefaultLayout>
              <ExpiredSessionPage />
            </DefaultLayout>
          }
        />
        <Route
          path={routes.confirmationPage}
          element={
            <DefaultLayout>
              <ConfirmationPage
                originSystem={get(storefrontData, 'salesChannel.originSystem')}
              />
            </DefaultLayout>
          }
        />
        <Route
          path={routes.transactionsApproval}
          element={
            <DefaultLayout>
              <TransactionsApproval />
            </DefaultLayout>
          }
        />
        <Route
          path={routes.transactionsConfirmed}
          element={
            <DefaultLayout>
              <TransactionsConfirmed />
            </DefaultLayout>
          }
        />
        <Route
          path={routes.transactionsRefusal}
          element={
            <DefaultLayout>
              <TransactionsRefusal />
            </DefaultLayout>
          }
        />
        <Route
          path={routes.transactionsCanceled}
          element={
            <DefaultLayout>
              <TransactionsCanceled />
            </DefaultLayout>
          }
        />
        <Route
          path={routes.transactionsError}
          element={
            <DefaultLayout>
              <TransactionsErrorPage />
            </DefaultLayout>
          }
        />
        <Route
          path={routes.licenses}
          element={
            <DefaultLayout>
              <ThirdPartyLicensePage />
            </DefaultLayout>
          }
        />
        <Route
          path={routes.dataProtectionInfo}
          element={
            <DefaultLayout>
              <DataProtectionInfoPage />
            </DefaultLayout>
          }
        />
        
        <Route
          path={routes.landingPage}
          element={
            <WithStorefront storefrontId={storefrontId}>
              <DefaultLayout>
                <AppForm />
              </DefaultLayout>
            </WithStorefront>
          }
        />

        <Route
          path="*"
          element={<Navigate to="/prefill" />}
        />
      </RouterRoutes>
    </>
  )
}

export default Routes

import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ContentSection } from '@vwfs-bronson/bronson-react'
import Heading from './common/Heading'
import BasicSection from './common/BasicSection'
import { scrollToElement } from '../../services/common/form'
import InstructionComponent from '../AppPage/InstructionStepper'
import { getStorefrontData } from '../../services/redux/features/storefront.redux'
import { getOriginSystem } from '../../services/common/utils'

const ConfirmationPageVAFA = () => {
  const storefrontData = useSelector(getStorefrontData)
  const originSystem = useMemo(() => getOriginSystem(storefrontData), [storefrontData])

  useEffect(() => {
    scrollToElement('.c-header')
  }, [])

  return (
    <>
      <ContentSection pageWrap>
        <Heading
          originSystem={originSystem}
          stepper={
            <InstructionComponent activeStep={3} originSystem={originSystem} />
          }
        />
        <div className="u-mt-large">
          <BasicSection
            section="receiveAndFileDocuments"
            originSystem={originSystem}
          />
        </div>
      </ContentSection>
    </>
  )
}

export default ConfirmationPageVAFA

import React, { useState } from 'react'

import { Button, Fieldset, Input, Layout } from '@vwfs-bronson/bronson-react'
import { phoneCountryCodes, countries } from '../../../resources/base/models'
import { Select } from '../../../components/Select'
import { FormField } from '../../../components/FormField'
import { FormFieldDate } from '../../../components/InputDate/InputDate'
import { LegalFormType } from '../../../models/enums'
import { mobilePhoneNumberSchema } from './validation-schema'
import { ZipCodeAutocomplete } from '../../../components/AddressAutocomplete/ZipCodeAutocomplete'
import { StreetAutocomplete } from '../../../components/AddressAutocomplete/StreetAutocomplete'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'

const CompanyName = () => {
  const { t } = useTranslation()

  return (
  <FormField
    testId="companyName"
    type="input"
    name="companyData.name"
    labelText={t('personal-details:companyName')}
    render={(fieldProps) => <Input {...fieldProps} />}
  />
)}

const EntityLegalForm = () => {
  const { t } = useTranslation()

  return (
    <FormField
      testId="entityLegalForm"
      type="select"
      name="companyData.entityLegalForm"
      labelText={t('personal-details:entityLegalForm.title')}
      render={(fieldProps) => (
        <Select {...fieldProps}>
          <Select.Item value={LegalFormType.SOLE_TRADER}>
            {t('personal-details:entityLegalForm.label.SOLE_TRADER')}
          </Select.Item>
          <Select.Item value={LegalFormType.REGISTERED_SOLE_TRADER}>
            {t('personal-details:entityLegalForm.label.REGISTERED_SOLE_TRADER')}
          </Select.Item>
          <Select.Item value={LegalFormType.FREELANCER}>
            {t('personal-details:entityLegalForm.label.FREELANCER')}
          </Select.Item>
        </Select>
      )}
    />
  )
}

const FirstName = () => {
  const { t } = useTranslation()

  return (
    <FormField
      testId="firstName"
      type="input"
      name="customerData.firstName"
      labelText={t('personal-details:firstName')}
      autoComplete='given-name'
      render={(fieldProps) => <Input {...fieldProps} />}
    />
  )
}

const LastName = () => {
  const { t } = useTranslation()

  return (
    <FormField
      testId="lastName"
      type="input"
      name="customerData.lastName"
      labelText={t('personal-details:lastName')}
      autoComplete='family-name'
      render={(fieldProps) => <Input {...fieldProps} />}
    />
  )
}

const Street = () => {
  const { t } = useTranslation()
  const { values } = useFormikContext()
  
  const isDisabled = () => {
    return (
      !(values as any)?.address?.[0]?.isManual &&
      !(values as any)?.address?.[0]?.city
    )
  }

  return (
    <FormField
      testId="street"
      type="input"
      name="customerData.address.street"
      labelText={t('personal-details:street')}
      autoComplete='address-line1'
      render={(fieldProps) => <Input {...fieldProps} disabled={isDisabled()} />}
    />
  )
}

const HouseNumber = () => {
  const { t } = useTranslation()
  const { values } = useFormikContext()

  const isDisabled = () => {
    return (
      !(values as any)?.address?.[0]?.isManual &&
      !(values as any)?.streetSelected
    )
  }

  return (
    <FormField
      testId="houseNumber"
      type="input"
      name="customerData.address.houseNumber"
      labelText={t('personal-details:houseNumber')}
      autoComplete='address-line2'
      render={(fieldProps) => <Input {...fieldProps} disabled={isDisabled()} />}
    />
  )
}

const ZipCode = () => {
  const { t } = useTranslation()

  return (
    <FormField
      testId="zipCode"
      type="input"
      name="customerData.address.zipCode"
      labelText={t('personal-details:zipCode')}
      autoComplete='postal-code'
      render={(fieldProps) => <Input {...fieldProps} />}
    />
  )
}

const City = () => {
  const { t } = useTranslation()
  const { values } = useFormikContext()
  const isManual = (values as any)?.address?.[0]?.isManual

  return (
    <FormField
      testId="city"
      type="input"
      name="customerData.address.city"
      labelText={t('personal-details:city')}
      autoComplete='address-level2'
      render={(fieldProps) => <Input {...fieldProps} disabled={!isManual} />}
    />
  )
}

const Email = () => {
  const { t } = useTranslation()

  return (
    <FormField
      testId="email"
      type="input"
      name="customerData.contactData.email"
      labelText={t('personal-details:email')}
      infoIcon={t('personal-details:email_tooltip')}
      autoComplete='email'
      render={(fieldProps) => <Input {...fieldProps} />}
    />
  )
}

const CountryCode = (props) => {
  const { t } = useTranslation()

  return (
    <FormField
      testId="countryCode"
      type="select"
      name="customerData.address.countryCode"
      labelText={t('personal-details:countryCode')}
      autoComplete='country-name'
      render={(fieldProps) => (
        <Select {...fieldProps}>
          <Select.Item
            key={`${countries[0].value[0]}${countries[0].label}`}
            selected={countries[0].value === props.value}
            value={countries[0].value}
          >
            {countries[0].label}
          </Select.Item>
        </Select>
      )}
    />
  )
}

const MobilePhoneCountryCode = () => {
  const { t } = useTranslation()

  return (
    <FormField
      testId="mobilePhoneCountryCode"
      type="select"
      name="customerData.contactData.mobilePhoneCountryCode"
      labelText={t('personal-details:mobilePhoneCountryCode')}
      autoComplete='tel-country-code'
      render={(fieldProps) => (
        <Select {...fieldProps}>
          {phoneCountryCodes.map(({ value, label }) => (
            <Select.Item key={`${value}${label}`} value={value}>
              {label}
            </Select.Item>
          ))}
        </Select>
      )}
    />
  )
}

const MobilePhoneNumber = ({ values, previousPhone, setPreviousPhone }) => {
  const { t } = useTranslation()
  
  const validationFunction = async (value) => {
    const mobilePhoneCountryCode =
      values?.customerData?.contactData?.mobilePhoneCountryCode
    try {
      await mobilePhoneNumberSchema.validate({
        mobilePhoneCountryCode,
        mobilePhoneNumber: value,
        previousValue: previousPhone,
      })
      if (mobilePhoneCountryCode && mobilePhoneCountryCode !== 'NONE') {
        // validation is successful (empty error message)
        setPreviousPhone({
          mobilePhoneCountryCode,
          mobilePhoneNumber: value,
          message: '',
        })
      }
      return null
    } catch (e: any) {
      setPreviousPhone({
        mobilePhoneCountryCode,
        mobilePhoneNumber: value,
        message: e?.message,
      })
      return e?.message
    }
  }

  return (
    <FormField
      testId="mobilePhoneNumber"
      type="input"
      name="customerData.contactData.mobilePhoneNumber"
      labelText={t('personal-details:mobilePhoneNumber')}
      validate={validationFunction}
      autoComplete='tel-national'
      render={(fieldProps) => <Input {...fieldProps} />}
    />
  )
}

const DateOfBirth = () => {
  const { t } = useTranslation()

  return (
    <FormFieldDate
      testId="dateOfBirth"
      name="customerData.dateOfBirth"
      labelText={t('personal-details:dateOfBirth')}
      autoComplete='bday'
    />
  )
}

export const RenderFieldset = ({
  values,
  isBusiness,
  isVtiGis,
}) => {
  const { t } = useTranslation()
  const { setFieldValue } = useFormikContext()
  const [previousPhone, setPreviousPhone] = useState({
    mobilePhoneCountryCode: '',
    mobilePhoneNumber: '',
    message: '',
  })
  return (
    <Fieldset>
      <Fieldset.Row>
        <Layout middle>
          {isBusiness && (
            <>
              {' '}
              <Layout.Item default="1/2" s="1/1">
                <CompanyName />
              </Layout.Item>
              <Layout.Item default="1/2" s="1/1">
                <EntityLegalForm />
              </Layout.Item>
            </>
          )}
          <Layout.Item default="1/2" s="1/1">
            <FirstName />
          </Layout.Item>
          <Layout.Item default="1/2" s="1/1">
            <LastName />
          </Layout.Item>
          {isVtiGis && (
            <>
              <Layout.Item default="1/2" s="1/1">
                <CountryCode />
              </Layout.Item>
              <Layout.Item default="1/2" s="1/1">
                <Button
                  className="u-mt"
                  type='button'
                  link
                  disabled={values?.address?.[0]?.isManual}
                  onClick={(e: any) => {
                    e.preventDefault()
                    setFieldValue(`address[0].isManual`, true)
                  }}
                >
                  {t('personal-details:manualMode')}
                </Button>
              </Layout.Item>
            </>
          )}
          <Layout.Item default="1/2" s="1/1">
            {values?.address?.[0]?.isManual ? (
              <ZipCode />
            ) : (
              <ZipCodeAutocomplete
                prefixCode={`${values?.customerData?.address.countryCode}`}
                label={t('personal-details:zipCode')}
              />
            )}
          </Layout.Item>
          <Layout.Item default="1/2" s="1/1">
            <City />
          </Layout.Item>
          <Layout.Item default="1/2" s="1/1">
            {values?.address?.[0]?.isManual ? (
              <Street />
            ) : (
              <StreetAutocomplete
                prefixCode={`${values?.customerData?.address.countryCode}`}
                label={t('personal-details:street')}
              />
            )}
          </Layout.Item>
          <Layout.Item default="1/2" s="1/1">
            <HouseNumber />
          </Layout.Item>
          {!isBusiness && (
            <Layout.Item default="1/2" s="1/1">
              <DateOfBirth />
            </Layout.Item>
          )}
          <Layout.Item default="1/2" s="1/1">
            <Email />
          </Layout.Item>
          {isBusiness && <Layout.Item default="1/2" s="1/1"></Layout.Item>}
          <Layout.Item default="1/2" s="1/1">
            <MobilePhoneCountryCode />
          </Layout.Item>
          <Layout.Item default="1/2" s="1/1">
            <MobilePhoneNumber
              values={values}
              previousPhone={previousPhone}
              setPreviousPhone={setPreviousPhone}
            />
          </Layout.Item>
        </Layout>
      </Fieldset.Row>
    </Fieldset>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '@vwfs-bronson/bronson-react'
import ContactPage from './contact-page'
import { pageModalProps } from '../../models/pageModalProps'
import { trackPage } from './tracking'

const ContactPageModal = (ContactPageModalprops: pageModalProps) => {
  React.useEffect(() => {
    if (ContactPageModalprops.visible) {
      trackPage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ContactPageModalprops.visible])

  const { t } = useTranslation()
  return (
    <Modal
      testId={ContactPageModalprops.testId}
      id="contactModal"
      shown={ContactPageModalprops.visible}
      onClose={ContactPageModalprops.onClose}
      onClickOutside={ContactPageModalprops.onClose}
      onConfirm={ContactPageModalprops.onClose}
      title={t('contact:headline')}
      buttonConfirmText={t('contact:closeBtn')}
    >
      <ContactPage />
    </Modal>
  )
}

export default ContactPageModal

import React, { FunctionComponent, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { trackPage } from './tracking'
import { isDealershop, isVaFa, isVtiGiS } from '../../services/common/utils'
import ConfirmationPageVTIGIS from './ConfirmationPageVTIGIS'
import ConfirmationPageVAFA from './ConfirmationPageVAFA'
import ConfirmationPageDEALERSHOP from './ConfirmationPageDEALERSHOP'
import { getStorefrontData } from '../../services/redux/features/storefront.redux'

export type ConfirmationPageProps = {
  originSystem: string
}

const ConfirmationPage: FunctionComponent<ConfirmationPageProps> = () => {
  const { t } = useTranslation()
  const storefrontData = useSelector(getStorefrontData)
  
  useEffect(() => {
    document.title = `${t('confirmation:tabTitle')}`
  }, [t])

  useEffect(() => {
    trackPage()
  }, [])

  return (
    <>
      {isDealershop(storefrontData) && (
        <ConfirmationPageDEALERSHOP />
      )}
      {isVaFa(storefrontData) && (
        <ConfirmationPageVAFA />
      )}
      {isVtiGiS(storefrontData) && (
        <ConfirmationPageVTIGIS />
      )}
    </>
  )
}

export default ConfirmationPage

import React from 'react'

import {
  FormField as CJFormField,
  ErrorMessage,
  Fieldset,
} from '@vwfs-bronson/bronson-react'
import { Field } from 'formik'
import get from 'lodash/get'
import { InfoTooltip } from '../InfoTooltip'

interface Props {
  render: Function
  name: string
  labelText?: string
  hint?: string
  infoIcon?: string
  type?: string
  className?: string
  testId?: string
  handleBlur?: Function
  labelOuter?: boolean
  autoComplete?: string
  validate?: any
}

export const FormField = ({
  render,
  name,
  labelText,
  hint,
  infoIcon,
  type,
  className,
  testId,
  handleBlur,
  autoComplete = 'off',
  validate,
}: Props) => {
  return (
    <Fieldset.Row>
      <Field
        name={name}
        validate={validate}
      >
        {({ field, form }) => {
          return (
            <CJFormField
              onBlur={(e) => handleBlur && handleBlur(e)}
              testId={testId}
              className={className}
              labelForId={name}
              labelText={labelText}
              infoIcon={infoIcon && <InfoTooltip label={labelText} text={infoIcon} />}
              type={
                type as
                  | 'input'
                  | 'select'
                  | 'textarea'
                  | 'checkbox'
                  | 'other'
                  | undefined
              }
              errorMessage={
                get(form.errors, name) && get(form.touched, name) ? (
                  <ErrorMessage id={name}>
                    {get(form.touched, name) && get(form.errors, name)}
                  </ErrorMessage>
                ) : (
                  ''
                )
              }
              hint={hint}
              noFloatingLabel
              staticLabel
            >
              {render({
                name,
                id: name,
                autoComplete,
                'aria-describedby': `error-${name}`,
                error: !!(get(form.touched, name)
                  ? get(form.errors, name)
                  : undefined),
                ...field,
              })}
            </CJFormField>
          )
        }}
      </Field>
    </Fieldset.Row>
  )
}

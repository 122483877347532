import React from 'react'
import {
  ContentSection,
  DescriptionList,
  Layout,
  Paragraph,
  Button,
  Heading,
} from '@vwfs-bronson/bronson-react'
import { useTranslation } from 'react-i18next'
import { Output } from '../../../Text'
import summaryTexts from '../../../resources/base/content/summary.json'
import './style.css'
import { LegalFormType } from '../../../models/enums'

const SummarySection = (props) => {
  const { t, i18n } = useTranslation()
  const { isBusiness, getValues } = props

  const getLegalFormText = (legalFormType: LegalFormType) => {
    return i18n.exists(
      `personal-details:entityLegalForm.label.${legalFormType}`
    )
      ? t(`personal-details:entityLegalForm.label.${legalFormType}`)
      : ''
  }

  const dataFieldsPersonalDetails = [
    getValues?.customerData?.firstName,
    getValues?.customerData?.lastName,
    getValues?.customerData?.address.street,
    getValues?.customerData?.address.houseNumber,
    getValues?.customerData?.address?.zipCode,
    getValues?.customerData?.address?.city,
    getValues?.customerData?.dateOfBirth,
    getValues?.customerData?.contactData?.email,
    getValues?.customerData?.contactData?.mobilePhoneNumber,
  ]
  const dataFieldsPersonalDetailsBusiness = [
    getValues?.companyData?.name,
    getLegalFormText(getValues?.companyData?.entityLegalForm),
    getValues?.customerData?.firstName,
    getValues?.customerData?.lastName,
    getValues?.customerData?.address.street,
    getValues?.customerData?.address.houseNumber,
    getValues?.customerData?.address?.zipCode,
    getValues?.customerData?.address?.city,
    getValues?.customerData?.dateOfBirth,
    getValues?.customerData?.contactData?.email,
    getValues?.customerData?.contactData?.mobilePhoneNumber,
  ]
  const descriptionListTitles = Output.resolve(
    `${isBusiness ? `descriptionListBusiness` : `descriptionList`}`,
    summaryTexts
  )

  return (
    <>
      {' '}
      <ContentSection.ComponentWrapper className="u-mb-none">
        <Heading level={2} className="u-text-left">{t('summary:title')}</Heading>
        <Paragraph className="u-text-left">{t('summary:subtitle')}</Paragraph>
        <Layout>
          <Layout.Item default="1/1" className="u-text-left">
            <DescriptionList>
              <DescriptionList.Group>
                <DescriptionList.Detail
                  className="editLinkSummary"
                  action={
                    <Button
                      icon="semantic-edit"
                      link
                      simple
                      type="button"
                      ariaLabel={t('summary:editButtonTitle')}
                      title={t('summary:editButtonTitle')}
                      onClick={() => {
                        const element = document.querySelector("input[name='customerData.firstName']") as HTMLInputElement
                        element?.scrollIntoView({ behavior: "smooth", block: "center" });
                        element?.focus();
                      }}
                    >
                      {t('summary:editButton')}
                    </Button>
                  }
                >
                  {t('summary:personalDataTitle')}
                </DescriptionList.Detail>
              </DescriptionList.Group>
            </DescriptionList>
          </Layout.Item>
          <Layout.Item>
            <Layout>
              {descriptionListTitles?.items?.length > 0 && (
                <Layout.Item default="1/1">
                  <DescriptionList grid>
                    {descriptionListTitles &&
                      descriptionListTitles?.items?.map(
                        (item: string, index: number) => (
                          <DescriptionList.Group key={item} termText={item}>
                            <DescriptionList.Detail>
                              {isBusiness
                                ? dataFieldsPersonalDetailsBusiness?.[index]
                                : dataFieldsPersonalDetails?.[index]}
                            </DescriptionList.Detail>
                          </DescriptionList.Group>
                        )
                      )}
                  </DescriptionList>
                </Layout.Item>
              )}
            </Layout>
          </Layout.Item>
        </Layout>
      </ContentSection.ComponentWrapper>
    </>
  )
}
export default SummarySection

// TODO: https://caniuse.com/urlsearchparams, I expect you still need to support IE11 otherwise use URLSearchParams
function qs(queryString, parameter) {
  const strToMap = queryString
    .slice(1)
    .split('&')
    .reduce((acc, item) => {
      const [name, ...value] = item.split('=')
      return {
        ...acc,
        [name]: value.join('='),
      }
    }, {})
  return strToMap[parameter] === undefined ? '' : strToMap[parameter]
}
export { qs }
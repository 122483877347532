/* eslint react/no-danger: 0 */
import React from 'react'
import {
  Card,
  Fieldset,
  ContentSection,
  Heading,
} from '@vwfs-bronson/bronson-react'
import { useSelector } from 'react-redux'
import { FastField } from 'formik'
import { getTlsValid } from '../../../services/redux/features/custom.redux'
import { CheckboxField } from '../../../components/CheckboxField'
import ExplanationComponent from './components/ExplanationComponent'
import NotesComponent from './components/NotesComponent'
import PrivacyComponent from './components/PrivacyComponent'
import { isDealershop, isVaFa, isVtiGiS } from '../../../services/common/utils'
import LimitationPeriod from '../LimitationPeriod'
import { getStorefrontData } from '../../../services/redux/features/storefront.redux'

const DataProtectionFieldset = ({
  t,
  inspectionReportLink,
}) => {
  const storefrontData = useSelector(getStorefrontData)
  const tlsValid = useSelector(getTlsValid)

  const dataProtectionUrl = () => {
    return isVtiGiS(storefrontData)
      ? '/documents/Datenschutzinformation_VTI_GmbH.pdf'
      : '/documents/23_0H_00_DSI_VWL_VaFa.pdf'
  }
  
  return (
    <>
      <ContentSection.ComponentWrapper className="u-mb-none">
        <Fieldset>
          {isVtiGiS(storefrontData) && (
            <ContentSection className="u-pt-small u-pb-small">
              <Card element='article' className="u-text-left u-pt-small u-pb-small">
                <Fieldset.Row>
                  <Heading level={2}>
                    {t('form:dataProtection:vtiGis:deviationAgreeTitle')}
                  </Heading>
                </Fieldset.Row>
                <Fieldset.Row>
                  <FastField
                    name="acceptVehicleDifferences"
                    label={
                      <>
                        <p>
                          {t(
                            'form:dataProtection:vtiGis:deviationAgreeCheckbox1'
                          )}
                        </p>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: t(
                              'form:dataProtection:vtiGis:deviationAgreeCheckbox1Text2',
                              { link: inspectionReportLink }
                            ),
                          }}
                        ></p>
                      </>
                    }
                    component={CheckboxField}
                  />
                </Fieldset.Row>
                <ContentSection className="u-pt-small u-pb-small">
                  <LimitationPeriod />
                </ContentSection>
              </Card>
            </ContentSection>
          )}
          {isDealershop(storefrontData) && (
            <ContentSection className="u-pt-small u-pb-small">
              <Card element='article' className="u-text-left">
                <LimitationPeriod />
              </Card>
            </ContentSection>
          )}
          <ContentSection className="u-pt-small u-pb-small">
            <ExplanationComponent />
          </ContentSection>
          {isVaFa(storefrontData) && (
            <ContentSection className="u-pt-small u-pb-small">
              <NotesComponent />
            </ContentSection>
          )}
          <ContentSection className="u-pt-small u-pb-small">
            <PrivacyComponent
              dataProtectionUrl={dataProtectionUrl}
              tlsValid={tlsValid}
            />
          </ContentSection>
        </Fieldset>
      </ContentSection.ComponentWrapper>
    </>
  )
}

export default DataProtectionFieldset

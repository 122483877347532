import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { Paragraph } from '@vwfs-bronson/bronson-react'
import { isENVKVNew } from '../../services/common/utils'
import ENVKVShoppingCartCaption from '../ENVKV/ENVKVShoppingCartCaption'
import routes from '../../routes'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import * as customActions from '../../services/redux/features/custom.redux'
import { RootState } from '../../services/redux'

type Props = {
  vehicleData?: any
}

const ShoppingCartImageFooter: FunctionComponent<Props> = ({ vehicleData }) => {
  const { t } = useTranslation('form:shoppingCart')
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const transactionId = useSelector((state: RootState) => state.storefront?.data?.transaction?.id);

  const suffix = Array.from(
    t('form:shoppingCart:imageFooter.suffix.texts', {
      returnObjects: true,
    }) as Array<string>
  )

  return isENVKVNew(vehicleData) ? (
    <ENVKVShoppingCartCaption
      onError={() => {
        dispatch(customActions.setErrorData({ url: window.location.href }));
        navigate(routes.errorPage)}
      }
      transactionId={transactionId}
      vehicleData={vehicleData}
    />
  ) : (
    <>
      {suffix?.length > 0 &&
        suffix?.map((text: any) => (
          <Paragraph key={text} dangerouslySetInnerHTML={{ __html: text }} />
        ))}
    </>
  )
}

export default ShoppingCartImageFooter

import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Paragraph,
  ContentSection,
  SectionHeading,
} from '@vwfs-bronson/bronson-react'

const TransactionsErrorPage: FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <ContentSection pageWrap>
      <SectionHeading level={1}>{t('transactions:error:title')}</SectionHeading>
      <Paragraph testId="errorText">{t('transactions:error:text')}</Paragraph>
    </ContentSection>
  )
}

export default TransactionsErrorPage

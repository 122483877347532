import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Layout, ItemTeaser, Paragraph } from '@vwfs-bronson/bronson-react'
import { useTranslation } from 'react-i18next'
import { trackEmail } from './tracking'
import { getStorefrontData } from '../../services/redux/features/storefront.redux'
import {
  getOriginSystem,
  isDealershop,
  isVaFa,
  isVtiGiS,
} from '../../services/common/utils'

const ContactPage = () => {
  const { t } = useTranslation()
  const storefrontData = useSelector(getStorefrontData)
  const originSystem = useMemo(() => getOriginSystem(storefrontData), [storefrontData])

  const getEmail = () => {
    if (isDealershop(storefrontData)) {
      return storefrontData?.dealerData?.contactData?.email
    } else {
      return t(`contact:mail:${originSystem}`, {
        defaultValue: t('contact:mail:default'),
      })
    }
  }

  const buildEmailBody = () => {
    let html = `${t(`contact:mail:title`)}\n\n`

    if (storefrontData?.vehicleData?.model?.description) {
      html += `${t(`contact:mail:description`)}${
        storefrontData?.vehicleData?.model?.description
      }\n`
    }
    if (storefrontData?.vehicleData?.vin) {
      html += `${t('contact:mail:vin')}${storefrontData?.vehicleData?.vin}\n`
    }
    if (storefrontData?.transaction?.storefrontTransactionId) {
      html += `${t('contact:mail:storefrontTransactionId')}${
        storefrontData?.transaction?.storefrontTransactionId
      }\n`
    }
    if (storefrontData?.transaction?.id) {
      html += `${t('contact:mail:transactionId')}${
        storefrontData?.transaction?.id
      }\n`
    }
    if (originSystem) {
      html += `${t('contact:mail:originSystem')}${originSystem}\n`
    }

    return encodeURIComponent(html)
  }

  const buildMailTo = () =>
    `mailto:${getEmail()}?subject=${t(`contact:mail:subject`)}${
      storefrontData?.vehicleData?.model?.description
    }&body=${buildEmailBody()}`

  return (
    <React.Fragment>
      <Layout className="u-pb-small">
        <Layout.Item>
          <Paragraph testId="contactText" className="u-mt">
            {t('contact:headText')}
          </Paragraph>
        </Layout.Item>
        <Layout.Item default="1/1">
          <ItemTeaser
            testId="mailItemTeaser"
            icon={t(`contact:itemTeaser1:${originSystem}:icon`, {
              defaultValue: t(`contact:itemTeaser1:default:icon`),
            })}
            title={t(`contact:itemTeaser1:${originSystem}:heading`, {
              defaultValue: t(`contact:itemTeaser1:default:heading`),
            })}
            linkProps={{
              element: 'a',
              target: '_blank',
              href: buildMailTo(),
            }}
            linkLabel={getEmail()}
            onClick={trackEmail}
          >
            {t(`contact:itemTeaser1:${originSystem}:text`, {
              defaultValue: t(`contact:itemTeaser1:default:text`),
            })}
          </ItemTeaser>
        </Layout.Item>
        {(isVtiGiS(storefrontData) || isVaFa(storefrontData)) && (
          <Layout.Item default="1/1">
            <ItemTeaser
              icon={t(
                `contact:phoneNumber:${getOriginSystem(storefrontData)}:icon`
              )}
              title={t(
                `contact:phoneNumber:${getOriginSystem(storefrontData)}:title`
              )}
            >
              {t(`contact:phoneNumber:${getOriginSystem(storefrontData)}:text`)}
            </ItemTeaser>
          </Layout.Item>
        )}
      </Layout>
    </React.Fragment>
  )
}

export default ContactPage

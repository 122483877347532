import React, { FunctionComponent } from 'react'
import { Card, SectionHeading } from '@vwfs-bronson/bronson-react'
import TextsRender from './TextsRender'
import DescriptionListRender from './DescriptionListRender'
import { Output } from '../../../Text'
import confirmationTexts from '../../../resources/base/content/confirmation.json'

export type BasicSectionProps = {
  originSystem: string
  templateValues?: any
  deliveryType?: string
  descriptionListValues?: any
  section: string
}

const BasicSection: FunctionComponent<BasicSectionProps> = (props) => {
  const {
    originSystem,
    templateValues,
    section,
    deliveryType,
    descriptionListValues,
  } = props

  const title = Output.resolve(
    `${originSystem}.${section}.title`,
    confirmationTexts
  )
  const texts = Output.resolve(
    `${originSystem}.${section}.${deliveryType ? `${deliveryType}.` : ''}texts`,
    confirmationTexts
  )
  const descriptionListTitles = Output.resolve(
    `${originSystem}.${section}.descriptionList`,
    confirmationTexts
  )

  return (
    <Card element='article' className="u-text-left">
      <SectionHeading level={2}>{title}</SectionHeading>
      {/* Texts */}
      <TextsRender texts={texts} templateValues={templateValues} />
      <DescriptionListRender
        descriptionList={descriptionListTitles}
        descriptionListData={descriptionListValues}
      />
    </Card>
  )
}

export default BasicSection

import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Paragraph,
  ContentSection,
  SectionHeading,
  Button,
} from '@vwfs-bronson/bronson-react'
import { useSelector } from 'react-redux'
import { trackPage } from './tracking'
import {
  getRedirectUrl,
  getStorefrontData,
} from '../../services/redux/features/storefront.redux'

const VehicleNotAvailablePage = () => {
  const { t } = useTranslation()
  const storefrontData = useSelector(getStorefrontData)
  const storefrontDataRedirectUrl = useSelector(getRedirectUrl)
  const redirectUrl = useMemo(
    () => storefrontData?.salesChannel?.redirectUrl || storefrontDataRedirectUrl,
    [storefrontData?.salesChannel?.redirectUrl, storefrontDataRedirectUrl]
  )

  useEffect(() => {
    trackPage()
  }, [])

  return (
    <ContentSection pageWrap>
      <SectionHeading level={1} testId="errorHeading">
        {t('vehicleNotAvailable:heading')}
      </SectionHeading>
      <Paragraph testId="errorText" className="u-text">
        {t('vehicleNotAvailable:text1')}
      </Paragraph>
      {redirectUrl && (
        <Paragraph testId="tryAgain" className="u-text-center">
          <Button
            id="tryAgainButton"
            testId="tryAgainButton"
            type="submit"
            onClick={() => {
              window.location.href = redirectUrl
            }}
          >
            {t('vehicleNotAvailable:button:text')}
          </Button>
        </Paragraph>
      )}
    </ContentSection>
  )
}

export default VehicleNotAvailablePage

import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { ContentSection, Spinner } from '@vwfs-bronson/bronson-react'
import routes from '../../../routes'
import { loadStorefrontDataToRedux } from './storefront.service'
import { saveSesionData } from '../../common/utils'
import { store } from '../../redux';
import * as customActions from '../../redux/features/custom.redux';
import { qs } from '../../routing';

const SpinnerSection = () => {
  return (
    <ContentSection pageWrap className="u-m-xlarge">
      <Spinner section />
    </ContentSection>
  )
}

export type WithStorefrontProps = {
  storefrontId: string
}

const WithStorefront: FunctionComponent<PropsWithChildren<WithStorefrontProps>> = ({
  storefrontId,
  children
}) => {
  const [isLoading, setLoading] = React.useState(true)
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (storefrontId) {
      const token = qs(document.location.search, 'token');

      saveSesionData({
        transactionId: storefrontId,
        token,
      })
      loadStorefrontDataToRedux(storefrontId, navigate)
        .catch(() => {
          store.dispatch(customActions.setErrorData({ url: window.location.href }));
          navigate(routes.errorPage)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }, [location.search, navigate, storefrontId])

  return (
    <>
      {isLoading && <SpinnerSection />}
      {!isLoading && children}
    </>
  )
}

export default WithStorefront

import { Modal, Paragraph, Spinner } from '@vwfs-bronson/bronson-react'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  confirmationApprove,
  confirmationApproveStatus,
  getConfirmationData,
} from '../../../services/api/confirmation'
import { store } from '../../../services/redux'
import * as actions from '../../../services/redux/features/storefront.redux'
import * as customActions from '../../../services/redux/features/custom.redux'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import routes from '../../../routes'
import { isCorrectEntryState } from '../transactions.utils'
import { BasicPollingStatus } from '../../../models/enums'

const TransactionsApprovalPage: FunctionComponent = () => {
  const { id, acceptanceId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storefrontData = useSelector(actions.getStorefrontData);

  const [isLoading, setIsLoading] = useState(false)
  const [isReady, setIsReady] = useState(false)

  const getData = async () => {
    if (!id) {
      dispatch(customActions.setErrorData({ url: window.location.href }));
      navigate(routes.errorPage)
      return;
    }

    setIsReady(false)
    
    try {
      const data = await getConfirmationData(id)
      if (!isCorrectEntryState(data)) {
        return navigate(routes.transactionsError)
      }
      store.dispatch(actions.storeId(id))
      store.dispatch(actions.initial(data))
    } catch {
      dispatch(customActions.setErrorData({ url: window.location.href }));
      navigate(routes.errorPage)
    } finally {
      setIsReady(true)
    }
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const getOriginSystem = () => storefrontData?.salesChannel?.originSystem

  const checkPolling = () => {
    if (!id || !acceptanceId) {
      dispatch(customActions.setErrorData({ url: window.location.href }));
      navigate(routes.errorPage)
      return;
    }

    let isPending = false
    const TIMEOUT = 250000
    const WAIT_TIME = 3000
    
    const finishPoll = () => {
      clearInterval(timer)
      clearTimeout(timeoutTimer)
      setIsLoading(false)
    }

    const checkStatus = (data: any) => {
      const status = data?.status
      switch (status) {
        case BasicPollingStatus.SUCCESS:
          finishPoll()
          navigate(routes.transactionsConfirmed)
          break
        case BasicPollingStatus.ERROR:
          finishPoll()
          dispatch(customActions.setErrorData({ url: window.location.href }));
          navigate(routes.errorPage)
          break
        default:
          break
      }
    }

    const executePoll = () => {
      if (!isPending) {
        isPending = true
        confirmationApproveStatus(id, acceptanceId)
          .then(
            (data: any) => checkStatus(data),
            () => {
              finishPoll()
              dispatch(customActions.setErrorData({ url: window.location.href }));
              navigate(routes.errorPage)
            }
          )
          .finally(() => {
            isPending = false
          })
      }
    }

    const timeoutTimer = setTimeout(() => finishPoll(), TIMEOUT)
    const timer = setInterval(executePoll, WAIT_TIME)
  }

  const onConfirm = async () => {
    if (!id || !acceptanceId) {
      dispatch(customActions.setErrorData({ url: window.location.href }));
      navigate(routes.errorPage)
      return;
    }

    setIsLoading(true)
    
    try {
      await confirmationApprove(id, acceptanceId)
      checkPolling()
    } catch {
      dispatch(customActions.setErrorData({ url: window.location.href }));
      navigate(routes.errorPage)
    }
  }

  return (
    <>
      {(isLoading || !isReady) && <Spinner center fullPage />}
      {isReady && (
        <Modal
          center
          title={t(`transactions:${getOriginSystem()}:approval:title`, {
            defaultValue: t(`transactions:default:approval:title`),
          })}
          shown={true}
          hideCloseButton
          buttonConfirmText={t(
            `transactions:${getOriginSystem()}:approval:confirmButton`,
            {
              defaultValue: t(`transactions:default:approval:confirmButton`),
            }
          )}
          onConfirm={onConfirm}
        >
          <Paragraph className="u-pt u-pb">
            {t(`transactions:${getOriginSystem()}:approval:subtitle`, {
              defaultValue: t(`transactions:default:approval:subtitle`),
            })}
          </Paragraph>
        </Modal>
      )}
    </>
  )
}

export default TransactionsApprovalPage

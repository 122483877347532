/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import {
  Button,
  ContentSection,
  Heading,
  Modal,
  Spinner,
} from '@vwfs-bronson/bronson-react'
import {
  done,
  getStoreFrontId,
  getStorefrontData,
} from '../../services/redux/features/storefront.redux'
import * as customActions from '../../services/redux/features/custom.redux'
import { StorefrontShoppingCart } from '../../components/ShoppingCart'
import {
  FormSectionGroup as Group,
  FormSectionHeader as Header,
  FormSectionContent as Content,
  FormSectionSection as Section,
  FormSectionSubtitle as Subtitle,
} from '../../components/FormSection'
import { HtmlContent } from '../../components/HtmlContent'
import {
  saveFormData as saveFormDataAction
} from '../../services/redux/features/form.redux'

import DataProtection from './DataProtection'
import { scrollToElement } from '../../services/common/form'
import PersonalDetails from './PersonalDetails'
import {
  isPrivate,
  isBusiness,
  isVtiGiS,
  saveSesionData,
  isDealershop,
  isVaFa,
} from '../../services/common/utils'
import { completeJourney } from '../../services/api/submitData/complete-journey'
import routes from '../../routes'
import { VehicleDefects } from '../../models/enums'
import { trackDirty } from './tracking'
import './appPage.css'
import EmailValidationModal from '../../components/EmailValidationModal/EmailValidationModal'
import AdditionalProducts from './AdditionalProducts'
import { setTokenExpiresAt as setTokenExpiresAtAction } from '../../services/redux/features/session.redux'
import InstructionComponent from './InstructionStepper'
import VehicleconditionVafa from './VehicleCondition/VehicleconditionVafa'
import VehicleConditionDealershop from './VehicleCondition/VehicleConditionDealershop'
import { getServiceAndInspection } from '../../services/redux/features/custom.redux'
import { additionalProducts } from '../../services/api/additionalProducts'
import { qs } from '../../services/routing';

const AppForm = ({ editPage = false }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const storefrontData = useSelector(getStorefrontData);
  const storefrontId = useSelector(getStoreFrontId);
  const serviceAndInspection = useSelector(getServiceAndInspection);
  
  const personalDetailsRef = useRef(undefined as any)
  const vehicleConditionRef = useRef(undefined as any)

  const [valuesPersonalForm, setValuesPersonalForm] = useState(undefined as any)
  const [hasDefectsSelected, setHasDefectsSelected] = useState(undefined as any)
  const [vehicleConditionValues, setVehicleConditionValues] = useState({} as any)
  const [openFormSection, setOpenFormSection] = useState(undefined as any)
  const [showErrorOnConnect, setshowErrorOnConnect] = useState(false)
  const [hasSubmitedDataProtection, setHasSubmitedDataProtection] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const [showEmailValidationModal, setShowEmailValidationModal] = useState(false)
  
  const isBusinessPath = () => isBusiness(storefrontData)
  const showVehicleCondition =
    (isPrivate(storefrontData) && isVaFa(storefrontData)) ||
    isDealershop(storefrontData)
  
  const validateAllForms = useCallback(async () => {
    await personalDetailsRef.current?.submit()
    const personalDataValidation = personalDetailsRef?.current?.validate()
    const vehicleConditionValidation = vehicleConditionRef?.current?.validate()

    return showVehicleCondition
      ? personalDataValidation && vehicleConditionValidation
      : personalDataValidation
  }, [showVehicleCondition])

  useEffect(() => {
    if (editPage) {
      const ids = ['personalDetails', 'contactDetails']
      const sectionNumber = Number(qs(location.search, 'section'))
      setOpenFormSection(ids[sectionNumber])
    }

    if (storefrontId) {
      document.title = t('form:tabTitle')
    } else {
      dispatch(customActions.setErrorData({ url: window.location.href }));
      navigate(routes.errorPage)
    }
  }, [dispatch, editPage, location.search, navigate, storefrontId, t])

  useEffect(() => {
    if (openFormSection) {
      validateAllForms()
      if (!document.querySelector('.is-error')) {
        scrollToElement('.c-form-section__header.is-active')
      }
    }
  }, [openFormSection, validateAllForms])

  useEffect(() => {
    dispatch(saveFormDataAction(vehicleConditionValues))
    setHasDefectsSelected(
      vehicleConditionValues.condition === VehicleDefects.DEFECTS
    )
  }, [dispatch, vehicleConditionValues])

  useEffect(() => {
    if (isDirty) {
      trackDirty()
    }
  }, [isDirty])

  const finishJourney = async (consentData) => {
    setIsLoading(true)

    try {
      const response = await completeJourney({
        customerData: personalDetailsRef.current?.getCustomerData(),
        ...(isBusinessPath() && {
          companyData: personalDetailsRef.current?.getCompanyData(),
        }),
        consentData,
        ...(showVehicleCondition &&
          !isDealershop(storefrontData) && {
            vehicleData: {
              condition: vehicleConditionValues.condition,
              defectsDescription: vehicleConditionValues.defectsDescription,
              odometer: Number(vehicleConditionValues.odometer),
            },
            ...(consentData && {
              consentData: {
                ...consentData,
                acceptVehicleDefects: vehicleConditionValues.acceptVehicleDefects,
                acceptLimitationPeriodVafa:
                  vehicleConditionValues.acceptLimitationPeriodVafa,
              },
            }),
          }),
        ...(showVehicleCondition &&
          isDealershop(storefrontData) && {
            consentData: {
              ...consentData,
              acceptVehicleDefects: vehicleConditionValues.acceptVehicleDefects,
              acceptUpdateObligation:
                vehicleConditionValues.acceptUpdateObligation,
            },
          }),
        ...((isVtiGiS(storefrontData) || isDealershop(storefrontData)) && {
          serviceAndInspection,
        }),
      })

      setIsLoading(false)

      const hasError = checkResult(response)
      if (!hasError) {
        saveSesionData({
          transactionId: storefrontId,
          token: response?.data?.token,
        })
        dispatch(setTokenExpiresAtAction(response?.data?.tokenExpiresAt))
        setShowEmailValidationModal(true)
      }
    } catch (error) {
      dispatch(customActions.setErrorData({ url: window.location.href }));
      navigate(routes.errorPage)
    }
  }

  const checkResult = (response) => {
    const status = response?.status?.toString()
    let target
    if (status !== '200') {
      if (status === '409') {
        target = routes.vehicleNotAvailablePage
      } else {
        target = routes.errorPage
      }
      navigate(target)
      return true
    }
    return false
  }

  const getFormSectionsValidSections = () => {
    return [false]
  }

  const onErrorFetch = () => {
    setshowErrorOnConnect(true)
  }
  const validProps = {
    finishJourney,
    onErrorFetch,
  }
  const getFormValuesDynamically = (values) => {
    setValuesPersonalForm(values)
  }
  const sections = [
    {
      id: 'personalDetails',
      alwaysOpen: false,
      trackingName: 'form:personalDetails:analyticsName',
      title: 'personal-details:title',
      subtitle: `personal-details:subtitle${
        // eslint-disable-next-line
        isVtiGiS(storefrontData)
          ? 'VtiGis'
          : isBusiness(storefrontData)
            ? 'VafaBusiness'
            : ''
      }`,
      child: (
        <PersonalDetails
          isBusiness={isBusinessPath()}
          isVtiGis={isVtiGiS(storefrontData)}
          {...validProps}
          ref={personalDetailsRef}
          editPage={editPage}
          setIsDirty={setIsDirty}
          getValues={getFormValuesDynamically}
        />
      ),
    },
  ]

  const showSection = (section) => {
    return section.id === openFormSection
  }

  const formSections = sections.map((section) => {
    return (
      <Section key={section.id} open={showSection(section)} id={section.id}>
        <Header>
          {!isBusinessPath() && <Heading level={2}>{t(section.title)}</Heading>}
          {section.subtitle && (
            <Subtitle>
              <HtmlContent>{t(section.subtitle)}</HtmlContent>
            </Subtitle>
          )}
        </Header>
        <Content>{section.child}</Content>
      </Section>
    )
  })

  const onClose = () => {
    setshowErrorOnConnect(false)
  }
  const hasSubmitedForm = (bool) => {
    setHasSubmitedDataProtection(bool)
  }

  const onCloseEmailValidationModal = () => {
    setShowEmailValidationModal(false)
  }

  // GFTDE-9665 in AC they specify to scroll to firstName instead of email field
  const goToForm = () => {
    const firstName = document.querySelector("input[name='customerData.firstName']") as HTMLInputElement | null
    if (firstName) {
      firstName.scrollIntoView({ behavior: "smooth", block: "center" });
      firstName.focus();
    }
    onCloseEmailValidationModal()
  }

  const onAdditionalProductSelection = async (product: any) => {
    setIsLoading(true)
    const { financialProduct } = storefrontData
    const updatedAdditionalProducts = financialProduct?.additionalProducts?.map(
      (p: any) =>
        p.code === product?.code
          ? {
              ...p,
              isSelected: !p?.isSelected,
            }
          : p
    )
    dispatch(done({
      ...storefrontData,
      financialProduct: {
        ...storefrontData?.financialProduct,
        additionalProducts: updatedAdditionalProducts,
      },
    }))
    const filterAdditionalProducts = updatedAdditionalProducts
      ?.filter((p: any) => p.isSelected)
      ?.map((p: any) => p.code)

    try {
      await additionalProducts(storefrontId, filterAdditionalProducts)
    } catch {
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {isLoading && <Spinner center fullPage />}
      <EmailValidationModal
        transactionId={storefrontData?.transaction?.id}
        email={
          personalDetailsRef.current?.getCustomerData()?.contactData?.email
        }
        onClose={onCloseEmailValidationModal}
        onVerify={goToForm}
        visible={showEmailValidationModal}
      />
      <Modal
        onClose={onClose}
        onClickOutside={onClose}
        title={t('error:heading')}
        shown={showErrorOnConnect}
      >
        <p> {t('error:text1')}</p>
        <Button testId="contactDetailsButton" type='button' onClick={onClose}>
          {t('form:btnContinue')}
        </Button>
      </Modal>
      <ContentSection pageWrap>
        <ContentSection.ComponentWrapper>
          <StorefrontShoppingCart />
        </ContentSection.ComponentWrapper>
      </ContentSection>
      <ContentSection pageWrap className="u-pt-xxsmall u-pb-xxsmall">
        <Heading level={1}>
          {t('stepper:title')}
        </Heading>
        <p>
          {t(`stepper:${storefrontData?.salesChannel?.originSystem}:text1`)}
          <br />
          {t(`stepper:text2`)}
        </p>
        <InstructionComponent
          activeStep={2}
          originSystem={storefrontData?.salesChannel?.originSystem}
        />
      </ContentSection>
      <ContentSection pageWrap className="u-pt u-pb-small">
        <ContentSection.ComponentWrapper>
          {!editPage || (editPage && openFormSection) ? (
            <Group
              validSections={getFormSectionsValidSections()}
              alwaysOpen={sections
                .filter((item) => item.alwaysOpen)
                .map((item) => item.id)}
            >
              {formSections}
            </Group>
          ) : (
            ''
          )}
        </ContentSection.ComponentWrapper>
      </ContentSection>
      {showVehicleCondition ? (
        <ContentSection pageWrap className="u-pt-small u-pb-small">
          <ContentSection.ComponentWrapper>
            {isDealershop(storefrontData) ? (
              <VehicleConditionDealershop
                ref={vehicleConditionRef}
                setIsDirty={setIsDirty}
                setVehicleConditionValues={setVehicleConditionValues}
                hasSubmited={hasSubmitedDataProtection}
              />
            ) : (
              <VehicleconditionVafa
                ref={vehicleConditionRef}
                setIsDirty={setIsDirty}
                setVehicleConditionValues={setVehicleConditionValues}
                hasSubmited={hasSubmitedDataProtection}
              />
            )}
          </ContentSection.ComponentWrapper>
        </ContentSection>
      ) : (
        <></>
      )}
      {isDealershop(storefrontData) || isVtiGiS(storefrontData) ? (
        <AdditionalProducts onSelection={onAdditionalProductSelection} />
      ) : (
        <></>
      )}
      <ContentSection pageWrap className="u-pt-none u-pb-none">
        <ContentSection.ComponentWrapper>
          <DataProtection
            finishJourney={finishJourney}
            hasDefectsSelected={hasDefectsSelected}
            validateAllForms={validateAllForms}
            isBusiness={isBusinessPath()}
            isVtiGis={isVtiGiS(storefrontData)}
            setIsDirty={setIsDirty}
            hasSubmited={hasSubmitedForm}
            getValues={valuesPersonalForm}
          />
        </ContentSection.ComponentWrapper>
      </ContentSection>
      {!isVtiGiS(storefrontData) && (
        <ContentSection pageWrap className="u-pt-small u-pb-small">
          <ContentSection.ComponentWrapper>
            <div className="u-font-size-fs-2">
              <p>{t('form:dataProtection:footnotes1')}</p>
              <p>{t('form:dataProtection:footnotes2')}</p>
            </div>
          </ContentSection.ComponentWrapper>
        </ContentSection>
      )}
    </>
  )
}

export default AppForm

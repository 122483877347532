import React, { FunctionComponent } from 'react'
import { Footer as BrFooter, BackToTop } from '@vwfs-bronson/bronson-react'
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux";
import { getStorefrontData } from '../../services/redux/features/storefront.redux';

export type FooterProps = {}

const Footer: FunctionComponent<FooterProps> = () => {
  const { t } = useTranslation()
  const storefrontData = useSelector(getStorefrontData);

  const buildCopyrightText = () => {
    return t(`footer:${storefrontData?.salesChannel?.originSystem}:copyright`, {
      companyName: storefrontData?.dealerData?.companyName,
      defaultValue: t(`footer:default:copyright`),
    })
  }

  const renderItems = () => {
    const items = t(
      `footer:${storefrontData?.salesChannel?.originSystem}:links`,
      {
        returnObjects: true,
        defaultValue: t(`footer:default:links`, { returnObjects: true }),
      }
    ) as any
    return (
      <>
        {items?.length &&
          items?.map((currentPage) => (
            <BrFooter.ModuleMetaNav.Item
              key={currentPage.url ? currentPage.url : undefined}
              href={currentPage.url ? currentPage.url : undefined}
              linkProps={{
                target: currentPage.target,
                normalLink: true,
              }}
            >
              {currentPage.name}
            </BrFooter.ModuleMetaNav.Item>
          ))}
      </>
    )
  }
  return (
    <>
      <BackToTop
        aria-label="Zur Startseite"
        onClick={() => {
          const skipLink = document.querySelector('#skipLink') as HTMLElement
          if (skipLink) {
            skipLink.scrollIntoView({ behavior: "smooth", block: "center" });
            skipLink.focus();
          }
        }}
      >{t('app:footer:backToTopLabel')}</BackToTop>

      <BrFooter>
        <BrFooter.ModuleMetaNav
          copyrightSign=""
          copyrightText={buildCopyrightText() as unknown as string}
        >
          {renderItems()}
        </BrFooter.ModuleMetaNav>
      </BrFooter>
    </>
  )
}

export default Footer

import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Paragraph,
  ContentSection,
  SectionHeading,
  OrderedList,
  UnorderedList,
} from '@vwfs-bronson/bronson-react'
import { connect } from 'react-redux'

export type DPInfoProps = {}

const DataProtectionInfoPage: FunctionComponent<DPInfoProps> = (props) => {
  const { t } = useTranslation()
  return (
    <ContentSection pageWrap>
      <SectionHeading level={1} center testId="errorHeading">
        {t('dataProtectionInfo:title')}
      </SectionHeading>
      <Paragraph>{t('dataProtectionInfo:text1')}</Paragraph>
      <Paragraph testId="errorText">
        <OrderedList>
          <OrderedList.Item>
            <b>{t('dataProtectionInfo:1:title')}</b>
          </OrderedList.Item>
          <Paragraph>{t('dataProtectionInfo:1:text1')}</Paragraph>
          <UnorderedList>
            <UnorderedList.Item>
              {t('dataProtectionInfo:1:listElement1')}
            </UnorderedList.Item>
            <UnorderedList.Item>
              {t('dataProtectionInfo:1:listElement2')}
            </UnorderedList.Item>
            <UnorderedList.Item>
              {t('dataProtectionInfo:1:listElement3')}
            </UnorderedList.Item>
            <UnorderedList.Item>
              {t('dataProtectionInfo:1:listElement4')}
            </UnorderedList.Item>
          </UnorderedList>
          <OrderedList.Item>
            <b>{t('dataProtectionInfo:2:title')}</b>
          </OrderedList.Item>
          <Paragraph>{t('dataProtectionInfo:2:text1')}</Paragraph>
          <OrderedList.Item>
            <b>{t('dataProtectionInfo:3:title')}</b>
          </OrderedList.Item>
          <Paragraph>{t('dataProtectionInfo:3:text1')}</Paragraph>
          <UnorderedList>
            <UnorderedList.Item>
              {t('dataProtectionInfo:3:listElement1')}
            </UnorderedList.Item>
            <UnorderedList.Item>
              {t('dataProtectionInfo:3:listElement2')}
            </UnorderedList.Item>
          </UnorderedList>
          <Paragraph>{t('dataProtectionInfo:3:text2')}</Paragraph>

          <OrderedList.Item>
            <b>{t('dataProtectionInfo:4:title')}</b>
          </OrderedList.Item>
          <Paragraph>{t('dataProtectionInfo:4:text1')}</Paragraph>
          <UnorderedList>
            <UnorderedList.Item>
              {t('dataProtectionInfo:4:listElement1')}
            </UnorderedList.Item>
            <UnorderedList.Item>
              {t('dataProtectionInfo:4:listElement2')}
            </UnorderedList.Item>
            <UnorderedList.Item>
              {t('dataProtectionInfo:4:listElement3')}
            </UnorderedList.Item>
          </UnorderedList>

          <OrderedList.Item>
            <b>{t('dataProtectionInfo:5:title')}</b>
          </OrderedList.Item>
          <Paragraph>{t('dataProtectionInfo:5:text1')}</Paragraph>
          <UnorderedList>
            <UnorderedList.Item>
              {t('dataProtectionInfo:5:listElement1')}
            </UnorderedList.Item>
            <UnorderedList.Item>
              {t('dataProtectionInfo:5:listElement2')}
            </UnorderedList.Item>
            <UnorderedList.Item>
              {t('dataProtectionInfo:5:listElement3')}
            </UnorderedList.Item>
          </UnorderedList>
          <Paragraph>{t('dataProtectionInfo:5:text2')}</Paragraph>

          <OrderedList.Item>
            <b>{t('dataProtectionInfo:6:title')}</b>
          </OrderedList.Item>
          <Paragraph>{t('dataProtectionInfo:6:text1')}</Paragraph>
          <UnorderedList>
            <UnorderedList.Item>
              {t('dataProtectionInfo:6:listElement1')}
            </UnorderedList.Item>
            <UnorderedList.Item>
              {t('dataProtectionInfo:6:listElement2')}
            </UnorderedList.Item>
          </UnorderedList>
          <OrderedList.Item>
            <b>{t('dataProtectionInfo:7:title')}</b>
          </OrderedList.Item>
          <Paragraph>{t('dataProtectionInfo:7:text1')}</Paragraph>
          <UnorderedList>
            <UnorderedList.Item>
              {t('dataProtectionInfo:7:listElement1')}
            </UnorderedList.Item>
            <UnorderedList.Item>
              {t('dataProtectionInfo:7:listElement2')}
            </UnorderedList.Item>
            <UnorderedList.Item>
              {t('dataProtectionInfo:7:listElement3')}
            </UnorderedList.Item>
          </UnorderedList>
          <Paragraph>{t('dataProtectionInfo:7:text2')}</Paragraph>
          <OrderedList.Item>
            <b>{t('dataProtectionInfo:8:title')}</b>
          </OrderedList.Item>
          <Paragraph>{t('dataProtectionInfo:8:text1')}</Paragraph>
          <Paragraph>{t('dataProtectionInfo:8:text2')}</Paragraph>
          <Paragraph>{t('dataProtectionInfo:8:text3')}</Paragraph>
          <UnorderedList>
            <UnorderedList>
              <UnorderedList.Item>
                <b>{t('dataProtectionInfo:8:list1:title')}</b>
                <Paragraph>{t('dataProtectionInfo:8:list1:text1')}</Paragraph>
                <Paragraph>{t('dataProtectionInfo:8:list1:text2')}</Paragraph>
                <Paragraph>{t('dataProtectionInfo:8:list1:text3')}</Paragraph>
              </UnorderedList.Item>

              <UnorderedList.Item>
                <b>{t('dataProtectionInfo:8:list2:title')}</b>
                <Paragraph>{t('dataProtectionInfo:8:list2:text1')}</Paragraph>
                <UnorderedList>
                  <UnorderedList.Item>
                    {t('dataProtectionInfo:8:list2:sublist1:element1')}
                  </UnorderedList.Item>
                  <UnorderedList.Item>
                    {t('dataProtectionInfo:8:list2:sublist1:element2')}
                  </UnorderedList.Item>
                </UnorderedList>
                <Paragraph>{t('dataProtectionInfo:8:list2:text2')}</Paragraph>
                <UnorderedList>
                  <UnorderedList.Item>
                    {t('dataProtectionInfo:8:list2:sublist2:element1')}
                  </UnorderedList.Item>
                  <UnorderedList.Item>
                    {t('dataProtectionInfo:8:list2:sublist2:element2')}
                  </UnorderedList.Item>
                </UnorderedList>
                <Paragraph>{t('dataProtectionInfo:8:list2:text3')}</Paragraph>
                <Paragraph className="u-text-center">
                  <b>{t('dataProtectionInfo:8:text4')}</b>
                </Paragraph>
                <Paragraph>
                  <b>{t('dataProtectionInfo:8:adobeAnalitics:title')}</b>
                </Paragraph>
                <Paragraph>
                  {t('dataProtectionInfo:8:adobeAnalitics:text1')}
                </Paragraph>
                <Paragraph>
                  {t('dataProtectionInfo:8:adobeAnalitics:text2')}
                </Paragraph>
                <Paragraph>
                  {t('dataProtectionInfo:8:adobeAnalitics:text3')}
                </Paragraph>
                <Paragraph>
                  {t('dataProtectionInfo:8:adobeAnalitics:text4')}
                  <a href={t('dataProtectionInfo:8:adobeAnalitics:link1')}>
                    {t('dataProtectionInfo:8:adobeAnalitics:link1')}
                  </a>
                </Paragraph>
                <Paragraph>
                  {t('dataProtectionInfo:8:adobeAnalitics:text5')}
                  <a href={t('dataProtectionInfo:8:adobeAnalitics:link2')}>
                    {t('dataProtectionInfo:8:adobeAnalitics:link2Text')}
                  </a>
                </Paragraph>
              </UnorderedList.Item>
            </UnorderedList>
          </UnorderedList>
          <OrderedList.Item>
            <b>{t('dataProtectionInfo:9:title')}</b>
          </OrderedList.Item>
          <Paragraph>{t('dataProtectionInfo:9:text1')}</Paragraph>
          <UnorderedList>
            <UnorderedList.Item>
              {t('dataProtectionInfo:9:list1:text1')}
            </UnorderedList.Item>
            <UnorderedList.Item>
              {t('dataProtectionInfo:9:list1:text2')}
            </UnorderedList.Item>
            <UnorderedList.Item>
              {t('dataProtectionInfo:9:list1:text3')}
            </UnorderedList.Item>
            <UnorderedList.Item>
              {t('dataProtectionInfo:9:list1:text4')}
            </UnorderedList.Item>
            <UnorderedList.Item>
              {t('dataProtectionInfo:9:list1:text5')}
            </UnorderedList.Item>
            <UnorderedList.Item>
              {t('dataProtectionInfo:9:list1:text6')}
            </UnorderedList.Item>
            <UnorderedList.Item>
              {t('dataProtectionInfo:9:list1:text7')}
            </UnorderedList.Item>
          </UnorderedList>
          <Paragraph>{t('dataProtectionInfo:9:text2')}</Paragraph>
          <Paragraph>
            <b>{t('dataProtectionInfo:9:text3')}</b>
          </Paragraph>
          <Paragraph>
            <b>{t('dataProtectionInfo:9:text4')}</b>
          </Paragraph>
          <Paragraph>
            <b>
              <u>{t('dataProtectionInfo:9:text5')}</u>
            </b>
          </Paragraph>
          <Paragraph>
            {t('dataProtectionInfo:9:text6')}
            <br />
            <b>{t('dataProtectionInfo:9:postalAddress:text1')}</b>
            <br />
            {t('dataProtectionInfo:9:postalAddress:text2')}
            <br />
            {t('dataProtectionInfo:9:postalAddress:text3')}
            <br />
            {t('dataProtectionInfo:9:postalAddress:text4')}
          </Paragraph>
          <Paragraph>{t('dataProtectionInfo:9:text7')}</Paragraph>
          <Paragraph>{t('dataProtectionInfo:9:text8')}</Paragraph>
          <OrderedList.Item>
            <b>{t('dataProtectionInfo:10:title')}</b>
          </OrderedList.Item>
          <Paragraph>{t('dataProtectionInfo:10:text1')}</Paragraph>
          <Paragraph>{t('dataProtectionInfo:10:text2')}</Paragraph>
          <OrderedList.Item>
            <b>{t('dataProtectionInfo:11:title')}</b>
          </OrderedList.Item>
          <Paragraph>{t('dataProtectionInfo:11:text1')}</Paragraph>
          <Paragraph>{t('dataProtectionInfo:11:text2')}</Paragraph>
        </OrderedList>
      </Paragraph>
      <Paragraph testId="linkErrorText" className="u-text-center"></Paragraph>
    </ContentSection>
  )
}

export default connect()(DataProtectionInfoPage)

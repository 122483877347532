import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Card, ContentSection, SectionHeading } from '@vwfs-bronson/bronson-react'

import Heading from './common/Heading'
import BasicSection from './common/BasicSection'
import { MarketingCards } from '../../components/MarketingCards'
import { DealerRole } from '../../models/enums'
import { formatPrice } from '../../services/common/format'
import { scrollToElement } from '../../services/common/form'
import InstructionComponent from '../AppPage/InstructionStepper'
import { getServiceAndInspection } from '../../services/redux/features/custom.redux'
import AdditionalProductsSection from './common/AdditionalProductsSection'
import DescriptionListRender from './common/DescriptionListRender'
import { getStorefrontData } from '../../services/redux/features/storefront.redux'
import { getOriginSystem } from '../../services/common/utils'

const ConfirmationPageVTIGIS = () => {
  const { t } = useTranslation()
  const storefrontData = useSelector(getStorefrontData)
  const serviceAndInspection = useSelector(getServiceAndInspection)
  const originSystem = useMemo(() => getOriginSystem(storefrontData), [storefrontData])
  const deliveryType = useMemo(() => storefrontData?.vehicleData?.delivery?.deliveryType, [storefrontData])

  const getDealerByRole = (role: string) => {
    return (
      storefrontData?.dealerData?.additionalDealers?.find(
        (dealer: any) => [role].includes(dealer.dealerRole)
      ) || null
    )
  }

  const dealer = getDealerByRole(DealerRole.PICKUP)

  const authorizeAndObtainVehicleTemplateValues = {
    companyName: dealer?.companyName,
    street: dealer?.addresses?.[0]?.street,
    houseNumber: dealer?.addresses?.[0]?.houseNumber,
    zipCode: dealer?.addresses?.[0].zipCode,
    city: dealer?.addresses?.[0].city,
  }

  useEffect(() => {
    scrollToElement('.c-header')
  }, [])

  const descriptionListValues = [
    formatPrice(storefrontData?.financialProduct?.totalAmount?.grossAmount),
    t(
      `confirmation:${originSystem}:transferPurchasePrice:descriptionListValues:paymentType`
    ),
    t(
      `confirmation:${originSystem}:transferPurchasePrice:descriptionListValues:accountOwner`
    ),
    t(
      `confirmation:${originSystem}:transferPurchasePrice:descriptionListValues:iban`
    ),
    t(
      `confirmation:${originSystem}:transferPurchasePrice:descriptionListValues:bic`
    ),
    storefrontData?.vehicleData?.vin,
  ]

  return (
    <>
      <ContentSection pageWrap>
        <Heading
          originSystem={originSystem}
          stepper={
            <InstructionComponent activeStep={3} originSystem={originSystem} />
          }
        />
        <div className="u-mt-large">
          <BasicSection
            section="receiveAndFileDocuments"
            originSystem={originSystem}
          />
        </div>

        <div className="u-mt-large">
          <BasicSection
            section="transferPurchasePrice"
            originSystem={originSystem}
            descriptionListValues={descriptionListValues}
          />
        </div>

        <div className="u-mt-large">
          <Card
            element='article'
            className="u-text-left"
          >
            <SectionHeading level={2}>
              {t(`confirmation:${originSystem}.authorizeAndObtainVehicle.title`)}
            </SectionHeading>
            {t(`confirmation:${originSystem}.authorizeAndObtainVehicle.${deliveryType || ''}.text1`)}
            <SectionHeading level={3} className="u-mt-large">
              {t(`confirmation:${originSystem}.authorizeAndObtainVehicle.${deliveryType || ''}.text2`)}
            </SectionHeading>
            {t(`confirmation:${originSystem}.authorizeAndObtainVehicle.${deliveryType || ''}.text3`)}
            <DescriptionListRender
              descriptionList={t(`confirmation:${originSystem}.authorizeAndObtainVehicle.descriptionList`)}
              descriptionListData={descriptionListValues}
              templateValues={authorizeAndObtainVehicleTemplateValues}
            />
          </Card>
        </div>

        {serviceAndInspection ? (
          <div className="u-mt-large">
            <AdditionalProductsSection
              transactionId={storefrontData?.transaction?.id}
              manufacturer={storefrontData?.vehicleData?.model?.manufacturer}
              serviceAndInspection={serviceAndInspection}
            />
          </div>
        ) : (
          <div className="u-mt-large">
            <MarketingCards
              manufacturer={storefrontData?.vehicleData?.model?.manufacturer}
              hideMotorInsurance
              storefrontData={storefrontData}
            />
          </div>
        )}
      </ContentSection>
    </>
  )
}

export default ConfirmationPageVTIGIS

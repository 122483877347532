/* eslint-disable max-len */
import React from 'react'
import {
  Button,
  ContentSection,
  DescriptionList,
  Layout,
  Paragraph,
  Heading,
} from '@vwfs-bronson/bronson-react'
import { useTranslation } from 'react-i18next'
import { scrollToElement } from '../../../services/common/form'
import './style.css'
import {
  addThousandSeparator,
  date,
  formatPrice,
} from '../../../services/common/format'
import { VehicleDefects } from '../../../models/enums'
import { useSelector } from 'react-redux'
import {
  getOriginSystem,
  isBusiness,
  isVaFa,
} from '../../../services/common/utils'
import SummaryVehicleSectionSuffix from './SummaryVehicleSectionSuffix'
import { InfoTooltip } from '../../../components/InfoTooltip'
import { getStorefrontData } from '../../../services/redux/features/storefront.redux'

const SummaryVehicleSection = ({
  vehicleData,
  purchaseDate,
  selectedCondition,
  defectsDescription
}) => {
  const { t } = useTranslation()
  const storefrontData = useSelector(getStorefrontData)

  const vehicleDataFormated = [
    vehicleData?.model?.manufacturer,
    vehicleData?.model?.description,
    vehicleData?.vin,
    date(vehicleData?.initialRegistrationDate),
    ...(isVaFa(storefrontData) ? [formatPrice(vehicleData?.purchasePriceAmount)] : []),
    addThousandSeparator(vehicleData?.odometer),
    // eslint-disable-next-line
    isVaFa(storefrontData) ? date(purchaseDate) : '',
  ]
  const descriptionList = t(
    `summaryVehicle:${getOriginSystem(storefrontData)}:descriptionList`,
    {
      returnObjects: true,
      defaultValue: t(`summaryVehicle:default:descriptionList`, {
        returnObjects: true,
      }),
    }
  ) as any
  return (
    <>
      {' '}
      <ContentSection.ComponentWrapper className="u-mb-none">
        <Heading level={2} className="u-text-left">{t('summaryVehicle:title')}</Heading>
        <Layout>
          {descriptionList?.items?.length > 0 && (
            <Layout.Item>
              <Layout>
                <Layout.Item default="1/1">
                  <DescriptionList grid className="summaryVehicle">
                    {descriptionList &&
                      descriptionList?.items?.map(
                        (item: string, index: number) => (
                          <DescriptionList.Group
                            key={item}
                            termText={
                              <span>
                                {item}
                                {descriptionList?.tooltip?.[item] && (
                                  <InfoTooltip label={item} text={descriptionList?.tooltip?.[item]} />
                                )}
                              </span>
                            }
                          >
                            <DescriptionList.Detail>
                              <Paragraph>
                                {vehicleDataFormated?.[index]}
                              </Paragraph>
                            </DescriptionList.Detail>
                          </DescriptionList.Group>
                        )
                      )}
                  </DescriptionList>
                  {isVaFa(storefrontData) &&
                    !isBusiness(storefrontData) &&
                    selectedCondition === VehicleDefects.DEFECTS && (
                      <>
                        <Layout>
                          <Layout.Item default="1/1" className="u-text-left">
                            <DescriptionList>
                              <DescriptionList.Group>
                                <DescriptionList.Detail
                                  className="editLinkVehicle"
                                  action={
                                    <Button
                                      icon="semantic-edit"
                                      link
                                      simple
                                      type="button"
                                      onClick={() =>
                                        scrollToElement(
                                          "textarea[name='defectsDescription']"
                                        )
                                      }
                                    >
                                      {t(
                                        'summaryVehicle:privateAdditionalSection:editButton'
                                      )}
                                    </Button>
                                  }
                                >
                                  <Heading level={4}>
                                    {t(
                                      'summaryVehicle:privateAdditionalSection:vehicleDefectsTitle'
                                    )}
                                  </Heading>
                                </DescriptionList.Detail>
                              </DescriptionList.Group>
                            </DescriptionList>
                          </Layout.Item>
                          <Layout.Item default="1/1">
                            <Paragraph>{defectsDescription}</Paragraph>
                          </Layout.Item>
                          <Layout.Item default="1/1">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: t(
                                  'summaryVehicle:privateAdditionalSection:footerNotePrivate'
                                ),
                              }}
                            />
                          </Layout.Item>
                        </Layout>
                      </>
                    )}
                  {/* Summary Vehicle Section Suffix component */}
                  <SummaryVehicleSectionSuffix />
                </Layout.Item>
              </Layout>
            </Layout.Item>
          )}
        </Layout>
      </ContentSection.ComponentWrapper>
    </>
  )
}

export default SummaryVehicleSection

import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'

import React, { useEffect } from 'react'
import { createRoot } from 'react-dom/client';

import { BrowserRouter, useLocation } from 'react-router-dom'
import { Provider } from 'react-redux'

import './services/i18n'
import { store } from './services/redux'

import './services/common/validation'

import App from './App'
import { installCommonHeadersForBffServicesInterceptor } from './services/api/common/HeadersInterceptor'

const PositionRestore = () => {
  const location = useLocation()
  const [prevLocation, setPrevLocation] = React.useState(location.pathname)

  useEffect(() => {
    if (prevLocation !== location.pathname) {
      setPrevLocation(location.pathname)

      if (!location.state || location.state.sectionId === '') {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      }
    }
  }, [location, prevLocation])

  return null
}

function Init() {
  return (
    <React.Suspense fallback={null}>
      <Provider store={store}>
        <BrowserRouter>
          <PositionRestore />
          <App />
        </BrowserRouter>
      </Provider>
    </React.Suspense>
  )
}

const container = document.getElementById('root')
if (container) {
  const rootElement = createRoot(container)
  rootElement.render(<Init />);
}

installCommonHeadersForBffServicesInterceptor()

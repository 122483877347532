import { useMemo, useState } from "react"

export const useAriaLabel = (initialStatus = false) => {
    const [ariaLabelStatus, setAriaLabelStatus] = useState(initialStatus)
    
    const ariaLabel = useMemo(
        () => ariaLabelStatus ? 'Abschnitt ausblenden' : 'Abschnitt einblenden',
        [ariaLabelStatus]
    )


    return { ariaLabel, setAriaLabelStatus }
}
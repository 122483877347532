import React, { forwardRef, useMemo } from 'react'
import {
  Fieldset,
  Heading,
  Layout,
  Paragraph,
} from '@vwfs-bronson/bronson-react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FastField } from 'formik'
import { CheckboxField } from '../../../components/CheckboxField'
import { getOriginSystem } from '../../../services/common/utils'
import { getStorefrontData } from '../../../services/redux/features/storefront.redux'
import { markupBGB } from '../../../utils/utils'

const LimitationPeriod = forwardRef((props, ref) => {
  const { t } = useTranslation()
  const storefrontData = useSelector(getStorefrontData)
  const originSystem = useMemo(
    () => getOriginSystem(storefrontData),
    [storefrontData]
  )

  return (
    <>
      <Heading level={2} className="u-text-left">
        {t(`form:limitationPeriod:${originSystem}:title`)}
      </Heading>
      {(
        t(`form:limitationPeriod:${originSystem}:texts`, {
          companyName: storefrontData?.dealerData?.companyName,
          returnObjects: true,
        }) as Array<string>
      )?.map((text: string) => (
        <Paragraph
          className="u-text-left"
          key={text}
          dangerouslySetInnerHTML={{
            __html: markupBGB(text),
          }}
        />
      ))}
      <Layout>
        <Layout.Item default="1/1">
          <Fieldset.Row className="u-mt-xsmall">
            <FastField
              name={t(`form:limitationPeriod:${originSystem}:checkbox:name`)}
              label={t(`form:limitationPeriod:${originSystem}:checkbox:text`)}
              component={CheckboxField}
            />
          </Fieldset.Row>
        </Layout.Item>
      </Layout>
    </>
  )
})

export default LimitationPeriod

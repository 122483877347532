import React from 'react'
import { Checkbox, ErrorMessage } from '@vwfs-bronson/bronson-react'
import { getIn } from 'formik'

export function fieldToCheckbox({
  field,
  form,
  input,
  beforeChange,
  beforeBlur,
  autoComplete = "off",
  ...props
}) {
  const { name } = field
  const { errors, touched } = form
  const fieldError = getIn(errors, name)

  return {
    ...input,
    ...field,
    ...props,
    onChange: (e) => {
      if (beforeChange) {
        beforeChange(e)
      }

      field.onChange(e)
    },
    onBlur: (e) => {
      if (beforeBlur) {
        beforeBlur(e)
      }

      field.onBlur(e)
    },
    value: field.name,
    checked: Array.isArray(field.value) ? !!field.value?.length : !!field.value,
    error: getIn(touched, name) && !!fieldError,
    'aria-describedby': `error-${name}`,
    fieldError,
    isTouched: getIn(touched, name),
    autoComplete,
  }
}

export function CheckboxField({ children, ...props }) {
  const checkboxProps = fieldToCheckbox(props)
  const { fieldError, isTouched } = checkboxProps

  return (
    <>
      <Checkbox
        {...fieldToCheckbox(props)}
        children={children}
      >
        {props.label}
      </Checkbox>
      {isTouched && fieldError && (
        <ErrorMessage id={checkboxProps.name}>
          {fieldError}
        </ErrorMessage>
      )}
    </>
  )
}

import { useTranslation } from 'react-i18next'
import { Paragraph, Heading as BrHeading, Icon } from '@vwfs-bronson/bronson-react'
import { StorefrontShoppingCart } from '../../../components/ShoppingCart'
import { useEffect, useRef } from 'react'

const Heading = (props) => {
  const { originSystem, stepper } = props
  const { t, i18n } = useTranslation()
  const confirmationRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (confirmationRef.current) {
      confirmationRef.current.focus();
    }
  }, []);

  return (
    <>
      <div className="u-text-center">
        <Icon name='semantic-success' className='u-text-success' aria-hidden="true" />
        <div
          ref={confirmationRef} tabIndex={-1} > <BrHeading level={1} >
            {t(`confirmation:${originSystem}:title`)}
            <br />
            {t(`confirmation:${originSystem}:subtitle`)}
          </BrHeading>
        </div>
      </div>
      {i18n.exists(`confirmation:${originSystem}:text`) && (
        <Paragraph>{t(`confirmation:${originSystem}:text`)}</Paragraph>
      )}
      <div className="u-mt">
        <StorefrontShoppingCart defaultOpen />
      </div>
      <div className="u-mt-large">
        {stepper && <div className="u-mt">{stepper}</div>}
      </div>
    </>
  )
}

export default Heading

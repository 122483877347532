import React, { useState } from 'react'
import {
  Header as BrHeader,
  ContextBar,
  Icon,
  Logo,
} from '@vwfs-bronson/bronson-react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ContactPageModal from '../../pages/ContactPageModal'
import gebrauchtwagenLogo from '../../resources/images/logos/VWFS_SOB_Picto_Gebrauchtwagen_RGB_1row_left_aligned.svg'
import { isVWFS, isVtiGiS } from '../../services/common/utils'
import { getStorefrontData } from '../../services/redux/features/storefront.redux'

export type HeaderProps = {}

const Header = () => {
  const [showContact, setShowContact] = useState(false)
  const { t } = useTranslation()
  const storefrontData = useSelector(getStorefrontData)

  const bronsonVersion = process.env.REACT_APP_WEBSITE_BRONSON_VERSION;

  return (
    <>
      <BrHeader
        testId="appHeader"
        staticHeader
        logo={
          <div style={{ display: 'flex', maxHeight: '70px' }}>
            <Logo
              testId="appHeaderLogo"
              tabIndex={0}
              src={`https://cdn.bronson.vwfs.io/bluelabel/v/${bronsonVersion}/img/logo.svg`}
              srcSmall={`https://cdn.bronson.vwfs.io/bluelabel/v/${bronsonVersion}/img/logo-small-screen.svg`}
              alt="Volskwagen Financial Services Logo"
            />
            {isVWFS() && isVtiGiS(storefrontData) && (
              <img src={gebrauchtwagenLogo} alt="VW FS Gebrauchtwagen Logo" style={{ width: '50%' }} />
            )}
          </div>
        }
      >
        <ContextBar
          style={{
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <ContextBar.SiteNav>
            <ContextBar.SiteNav.Item>
              <li>
                  <button
                    type='button'
                    aria-label={t('contact:labelTitle')}
                    className="c-context-bar__link"
                    style={{border: 0}}
                    onClick={() => setShowContact(true)}
                  >
                    <Icon name='semantic-chat' className='c-context-bar__icon' aria-hidden="true" />
                  </button>
              </li>
            </ContextBar.SiteNav.Item>
          </ContextBar.SiteNav>
        </ContextBar>
      </BrHeader>

      <ContactPageModal
        testId="appContactPageModal"
        visible={showContact}
        onClose={() => setShowContact(false)}
      />
    </>
  )
}

export default Header

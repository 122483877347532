import React from 'react'
import { Card, Fieldset, Heading } from '@vwfs-bronson/bronson-react'
import { useTranslation } from 'react-i18next'

const NotesComponent = (props) => {
  const { t } = useTranslation()
  const { isVtiGis, isBusiness } = props
  return (
    <Card element='article' className="u-text-left">
      {!isVtiGis && !isBusiness && (
        <>
          <Fieldset.Row className="u-mt">
            <Heading level={4}>{t('form:dataProtection:warrantyTitle')}</Heading>
          </Fieldset.Row>
          <Fieldset.Row>
            {' '}
            <span
              dangerouslySetInnerHTML={{
                __html: t('form:dataProtection:warrantyText'),
              }}
            />
          </Fieldset.Row>
        </>
      )}
      {!isVtiGis && (
        <>
          <Fieldset.Row className="u-mt">
            <Heading level={4}>
              {t('form:dataProtection:endContractTitle')}
            </Heading>
          </Fieldset.Row>
          <Fieldset.Row>
            <p> {t('form:dataProtection:endContractText1')}</p>
            <p> {t('form:dataProtection:endContractText2')}</p>
          </Fieldset.Row>
        </>
      )}

      <Fieldset.Row className="u-mt">
        <Heading level={2}>{t('form:dataProtection:conditionsTitle')}</Heading>
      </Fieldset.Row>
      <Fieldset.Row>
        <p>
          {t(
            `form:dataProtection:conditionsText1:${
              isBusiness ? 'business' : 'private'
            }`
          )}
        </p>
        <p>
          {!isVtiGis &&
            t(
              `form:dataProtection:conditionsText2:${
                isBusiness ? 'business' : 'private'
              }`
            )}
        </p>
      </Fieldset.Row>
    </Card>
  )
}

export default NotesComponent

import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Paragraph,
  ContentSection,
  SectionHeading,
  Button,
} from '@vwfs-bronson/bronson-react'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import routes from '../../routes'
import { trackPage } from './tracking'
import { getStoreFrontId } from '../../services/redux/features/storefront.redux';

interface ErrorPageProps {
  entryUrl: string;
}

const ErrorPage = ({ entryUrl }: ErrorPageProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate();
  const storefrontId = useSelector(getStoreFrontId);

  const landingPageRoute = storefrontId
    ? `${routes.landingPage}?id=${storefrontId}`
    : routes.errorPage

  useEffect(() => {
    document.title = `${t('error:tabTitle')}`
    trackPage(entryUrl)
  }, [t, entryUrl])

  return (
    <ContentSection pageWrap>
      <SectionHeading level={1} center testId="errorHeading">
        {t('error:heading')}
      </SectionHeading>
      <Paragraph testId="errorText" className="u-text-center">
        {t('error:text1')}
      </Paragraph>
      <Paragraph testId="linkErrorText" className="u-text-center">
        <Button
          id="tryAgainButton"
          testId="tryAgainButton"
          type="submit"
          onClick={() => {
            navigate(landingPageRoute)
          }}
        >
          {t('error:button:text')}
        </Button>
      </Paragraph>
    </ContentSection>
  )
}

export default ErrorPage

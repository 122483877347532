import { InfoIcon } from "@vwfs-bronson/bronson-react";

interface Props {
    label?: string
    text: string
}

export const InfoTooltip = ({ label, text }: Props) => (
    <InfoIcon
        icon="semantic-info"
        aria-label={`Informationen${label ? ` ${label}` : ''}`}
        role='tooltip'
    >
        {text}
    </InfoIcon>
)
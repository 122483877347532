import { ContentSection, Heading, Icon, Paragraph } from '@vwfs-bronson/bronson-react'
import React, { FunctionComponent, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getStorefrontData } from '../../../services/redux/features/storefront.redux'
import { getOriginSystem } from '../../../services/common/utils'

const TransactionsCanceledPage: FunctionComponent = () => {
  const { t, i18n } = useTranslation()
  const storefrontData = useSelector(getStorefrontData)
  const originSystem = useMemo(() => getOriginSystem(storefrontData), [storefrontData])

  return (
    <ContentSection pageWrap>
      <div className="u-text-center">
        <Icon name='semantic-close' className='u-text-alert' aria-hidden="true" />
        <Heading level={1}>{t(`transactions:${originSystem}:canceled:title`)}</Heading>
      </div>
      <Paragraph>
        {t(`transactions:${originSystem}:canceled:subtitle:text`)}
      </Paragraph>
      {i18n.exists(
        `transactions:${originSystem}:canceled:subtitle:email`
      ) &&
        i18n.exists(
          `transactions:${originSystem}:canceled:subtitle:phone`
        ) && (
          <Paragraph>
            <span>
              {t(`transactions:${originSystem}:canceled:subtitle:email`, {
                email: storefrontData?.customerData?.contactData?.email,
              })}
            </span>
            <br />
            <span>
              {t(`transactions:${originSystem}:canceled:subtitle:phone`, {
                phone: `${storefrontData?.customerData?.contactData?.mobilePhoneCountryCode} ${storefrontData?.customerData?.contactData?.mobilePhoneNumber}`,
              })}
            </span>
          </Paragraph>
        )}
    </ContentSection>
  )
}

export default TransactionsCanceledPage

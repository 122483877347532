import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import * as customActions from '../../services/redux/features/custom.redux'
import {
  Badge,
  Button,
  ButtonContainer,
  Fieldset,
  Input,
  Layout,
  Modal,
  Spinner,
} from '@vwfs-bronson/bronson-react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { FormField } from '../FormField'
import { sendTan, tanValidation } from '../../services/api/mailValidation'
import routes from '../../routes'
import { setDealerPaymentData } from '../../services/redux/features/storefront.redux'
import { trackEmailValidationModal, trackInvalidCode } from './tracking'
import { saveSesionData } from '../../services/common/utils'
import { useNavigate } from 'react-router-dom'

const COOLDOWN_TIMER = 60

function EmailValidationModal({ transactionId, visible, onClose, onVerify, email }) {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = React.useState(false)
  const [counter, setCounter] = React.useState(0)

  let setFormErrors

  const initialValues = { code: '' }

  const ValidationSchema = Yup.object().shape({
    code: Yup.string().required(t('validation:required')),
  })

  const newTan = async () => {
    try {
      setIsLoading(true)
      const response = await sendTan(transactionId)
      saveSesionData({
        transactionId: transactionId,
        token: response?.data?.token,
      })
      setCounter(COOLDOWN_TIMER)
    } catch (error) {
      dispatch(customActions.setErrorData({ url: window.location.href }));
      navigate(routes.errorPage)
    } finally {
      setIsLoading(false)
    }
  }

  const handleErrorSubmit = (error) => {
    const status = error?.response?.status?.toString()
    switch (status) {
      case '400':
        setFormErrors({ code: t('validation:invalidTan') })
        break
      case '409':
        navigate(routes.vehicleNotAvailablePage)
        break
      default:
        dispatch(customActions.setErrorData({ url: window.location.href }));
        navigate(routes.errorPage)
    }
  }

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true)
      const response = await tanValidation(values?.code, transactionId)
      dispatch(setDealerPaymentData(response?.data?.dealerData?.paymentData))
      navigate(routes.confirmationPage)
      setIsLoading(false)
    } catch (error) {
      trackInvalidCode()
      handleErrorSubmit(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000)
      return () => clearTimeout(timer)
    }
  }, [counter])

  useEffect(() => {
    if (visible) {
      trackEmailValidationModal()
    }
  }, [visible])

  return (
    <>
      {isLoading && <Spinner center fullPage />}
      <Modal
        title={t('emailValidationModal:title')}
        buttonCloseLabel={t('emailValidationModal:buttonCloseLabel')}
        onClose={() => {
          if (onClose) {
            onClose()
          }
        }}
        shown={visible}
        stretch
      >
        <Layout className="u-mb-large">
          <Layout.Item default="1/1">
            {t('emailValidationModal:text', {
              email,
            })}
          </Layout.Item>
          <Layout.Item default="1/1">
            <Formik
              initialValues={initialValues}
              validationSchema={ValidationSchema}
              onSubmit={(e) => handleSubmit(e)}
            >
              {({ setErrors, setFieldValue }) => {
                setFormErrors = setErrors
                const handleChangeOwn = (ev) => {
                  if (ev.target.name === 'code') {
                    setFieldValue(
                      'code',
                      ev.target.value.replace(/\s+/g, '')
                    )
                  }
                }
                return (
                  <Form id="data-protection-form" onChange={handleChangeOwn}>
                    <Fieldset.Row>
                      <Layout center>
                        <Layout.Item default="1/2" className="u-mt">
                          <FormField
                            testId="code"
                            type="input"
                            name="code"
                            labelText={t('emailValidationModal:code')}
                            render={(fieldProps) => <Input {...fieldProps} />}
                          />
                        </Layout.Item>
                      </Layout>
                    </Fieldset.Row>
                    <Fieldset.Row className="u-text-center">
                      <Layout center>
                        <Layout.Item default="1/1">
                          <ButtonContainer center>
                            <Button className="u-text-center" type="submit">
                              {t('emailValidationModal:button')}
                            </Button>
                          </ButtonContainer>
                        </Layout.Item>
                        {counter > 0 && (
                          <Layout.Item
                            default="1/1"
                            className="u-mt u-text-center"
                          >
                            <Badge
                              id='badge-counter'
                              label={t('emailValidationModal:timeUnit')}
                              modifier="warning"
                            >
                              {counter}
                            </Badge>
                          </Layout.Item>
                        )}
                      </Layout>
                    </Fieldset.Row>
                  </Form>
                )
              }}
            </Formik>
          </Layout.Item>
          <Layout.Item default="1/1">
            <Layout>
              <Layout.Item default="1/2" className="u-text-left u-mt-large">
                <Button
                  type='button'
                  onClick={() => {
                    if (onVerify) {
                      onVerify()
                    }
                  }}
                  link
                  aria-label={t('personal-details:title')}
                >
                  {t('emailValidationModal:verifyLink')}
                </Button>
              </Layout.Item>
              <Layout.Item default="1/2" className="u-text-right u-mt-large">
                <Button
                  type='button'
                  onClick={() => {
                    newTan()
                  }}
                  link
                  disabled={counter > 0}
                  aria-describedby='badge-counter'
                >
                  {t('emailValidationModal:resendLink')}
                </Button>
              </Layout.Item>
            </Layout>
          </Layout.Item>
        </Layout>
      </Modal>
    </>
  )
}

export default EmailValidationModal

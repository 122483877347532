import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Formik, Form, FastField } from 'formik'
import {
  Fieldset,
  Layout,
  Select,
  Textarea,
  Input,
  Card,
  ContentSection,
  Paragraph,
  Heading,
} from '@vwfs-bronson/bronson-react'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import * as Yup from 'yup'
import { FormField } from '../../../components/FormField'
import { CheckboxField } from '../../../components/CheckboxField'
import { VehicleDefects } from '../../../models/enums'
import { useSelector } from 'react-redux'
import { getStorefrontData } from '../../../services/redux/features/storefront.redux'

interface Props {
  hasSubmited: boolean,
  setVehicleConditionValues: (param) => void,
  setIsDirty: (param: boolean) => void,
}

const VehicleConditionVafa = forwardRef(({
  hasSubmited,
  setVehicleConditionValues,
  setIsDirty: setIsDirtyParent,
}: Props, ref) => {
  const { t } = useTranslation()
  const storefrontData = useSelector(getStorefrontData)
  const [isDirty, setIsDirty] = useState(false)
  const formState = useRef(undefined as any)
  const submitFunctionRef = useRef<() => void>()

  useImperativeHandle(ref, () => ({
    submit: () => submitFunctionRef.current?.(),
    isValid: () => formState.current.isValid,
    validate: () => formState.current.isValid,
  }))

  useEffect(() => {
    setIsDirtyParent(isDirty)
  }, [isDirty, setIsDirtyParent])

  useEffect(() => {
    if (hasSubmited) {
      setTouchedForm(formState.current)
    }
  }, [hasSubmited])

  const onChangeForm = async (e, formProps) => {
    const { name, type, checked, value } = e.target;
    if (name) {
      const newValue = type === 'checkbox' ? checked : value
      await formProps.setFieldValue(name, newValue)
      await formProps.setFieldTouched(name)
      await setVehicleConditionValues({ ...formProps.values, [name]: newValue })
    }
  }

  const setTouchedForm = (formProps) => {
    Object.keys(formProps.values).forEach((field) => {
      formProps.setFieldTouched(field)
    })
  }

  const ValidationSchema = Yup.object().shape({
    odometer: Yup.string()
      .matches(/^\d+$/, i18n.t('validation:invalidCharacter'))
      .required(i18n.t('validation:required')),
    condition: Yup.string()
      .required(i18n.t('validation:required'))
      .oneOf(
        [VehicleDefects.NO_DEFECTS, VehicleDefects.DEFECTS],
        i18n.t('validation:required')
      ),
    defectsDescription: Yup.string().when('condition', (condition, schema) =>
      condition === VehicleDefects.DEFECTS
        ? schema.required(i18n.t('validation:required'))
        : schema
    ),
    acceptVehicleDefects: Yup.boolean().when('condition', (condition, schema) => 
      condition === VehicleDefects.DEFECTS
        ? schema
            .required(i18n.t('validation:required'))
            .oneOf([true], i18n.t('validation:required'))
        : schema
    ),    
    acceptLimitationPeriodVafa: Yup.boolean()
      .required(i18n.t('validation:required'))
      .oneOf([true], i18n.t('validation:required')),
  })
  
  return (
    <Formik
      innerRef={formState}
      initialValues={{
        odometer: storefrontData?.vehicleData?.odometer,
        acceptVehicleDefects: false,
        acceptLimitationPeriodVafa: false,
        condition: undefined,
        defectsDescription: '',
      }}
      onSubmit={() => {}}
      validationSchema={ValidationSchema}
      validateOnMount
    >
      {(formProps) => {
        setIsDirty(formProps.dirty)
        submitFunctionRef.current = formProps.submitForm

        return (
          <Form
            id="vehicle-condition-form"
            onChange={(e) => onChangeForm(e, formProps)}
          >
            <Card element='article' className="u-text-left">
              <Layout>
                <Layout.Item default="1/1" s="1/1">
                  <legend className="c-form-heading">
                    <strong className="c-form-heading__title">
                      <span className="c-form-heading__title-text">
                        {t('form:vehicleCondition:titleKmtsSection')}
                      </span>
                    </strong>
                  </legend>
                </Layout.Item>
                <Layout.Item default="1/4" s="1/1">
                  <Fieldset>
                    <Fieldset.Row>
                      <FormField
                        testId="odometer"
                        type="input"
                        name="odometer"
                        labelText={t('form:vehicleCondition:odometer')}
                        render={(fieldProps) => (
                          <Input
                            maxLength="20"
                            {...fieldProps}
                            value={storefrontData?.vehicleData?.odometer}
                            readOnly
                          />
                        )}
                      />
                    </Fieldset.Row>
                  </Fieldset>
                </Layout.Item>
                <Layout.Item default="1/1" s="1/1">
                  <legend className="c-form-heading">
                    <strong className="c-form-heading__title">
                      <span className="c-form-heading__title-text">
                        {t('form:vehicleCondition:title')}
                      </span>
                    </strong>
                  </legend>
                </Layout.Item>

                <Fieldset>
                  <Layout.Item default="1/2" s="1/1">
                    <Fieldset.Row>
                      <FormField
                        testId="condition"
                        type="select"
                        name="condition"
                        labelText={t('form:vehicleCondition:vehicleCondition')}
                        render={(fieldProps) => (
                          <Select {...fieldProps}>
                            <Select.Item value="">
                              {t(
                                'form:vehicleCondition:conditionLabels:choose'
                              )}
                            </Select.Item>
                            <Select.Item value={VehicleDefects.NO_DEFECTS}>
                              {t(
                                'form:vehicleCondition:conditionLabels:hasNotDefects'
                              )}
                            </Select.Item>
                            <Select.Item value={VehicleDefects.DEFECTS}>
                              {t(
                                'form:vehicleCondition:conditionLabels:hasDefects'
                              )}
                            </Select.Item>
                          </Select>
                        )}
                      />
                    </Fieldset.Row>
                  </Layout.Item>
                  {formProps.values.condition === VehicleDefects.DEFECTS && (
                    <>
                      <Layout.Item default="1/2" s="1/1">
                        <Fieldset.Row>
                          <FormField
                            testId="defectsDescription"
                            type="input"
                            name="defectsDescription"
                            labelText={t(
                              'form:vehicleCondition:vehicleConditionDescription'
                            )}
                            infoIcon={t(
                              'form:vehicleCondition:vehicleConditionDescription_tooltip'
                            )}
                            render={(fieldProps) => (
                              <Textarea rows="4" {...fieldProps} />
                            )}
                          />
                        </Fieldset.Row>
                      </Layout.Item>
                      <Layout.Item default="1/1" s="1/1">
                        <Fieldset.Row className="u-mt-large">
                          <FastField
                            name="acceptVehicleDefects"
                            label={
                              <span>
                                {t(
                                  'form:vehicleCondition:checkboxDeviationAccept'
                                )}
                              </span>
                            }
                            component={CheckboxField}
                          />
                        </Fieldset.Row>
                      </Layout.Item>
                    </>
                  )}
                  <ContentSection>
                    <Layout.Item default="1/1" s="1/1">
                      <Fieldset.Row>
                        <Heading level={4}>
                          {t('form:vehicleCondition:titleLimitationPeriod')}
                        </Heading>
                      </Fieldset.Row>
                    </Layout.Item>
                    <Layout.Item default="1/1" s="1/1">
                      <Paragraph
                        dangerouslySetInnerHTML={{
                          __html: t(
                            'form:vehicleCondition:fullLimitationPeriodDescription'
                          ),
                        }}
                      />
                    </Layout.Item>

                    <Layout.Item default="1/1" s="1/1">
                      <Fieldset.Row className="u-mt-large">
                        <FastField
                          name="acceptLimitationPeriodVafa"
                          label={t(
                            'form:vehicleCondition:checkboxAcceptLimitationPeriod'
                          )}
                          component={CheckboxField}
                        />
                      </Fieldset.Row>
                    </Layout.Item>
                  </ContentSection>
                </Fieldset>
              </Layout>
            </Card>
          </Form>
        )
      }}
    </Formik>
  )
})

export default VehicleConditionVafa

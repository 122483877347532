import React, { FunctionComponent, PropsWithChildren } from 'react'
import { Main } from '@vwfs-bronson/bronson-react'
import Header from './Header'
import Footer from './Footer'
import { SkipLink } from '../SkipLink'

export type DefaultLayoutProps = {}

const DefaultLayout: FunctionComponent<
  PropsWithChildren<DefaultLayoutProps>
> = ({ children }) => {
  return (
    <>
      <SkipLink id="main" />
      <Header />
      <Main id="main">{children}</Main>
      <Footer />
    </>
  )
}

export { DefaultLayout }

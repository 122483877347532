import React, { useEffect, useMemo, useState } from 'react'
import { Spinner } from '@vwfs-bronson/bronson-react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as customActions from './services/redux/features/custom.redux'
import { useNavigate } from 'react-router-dom';
import { getBrandName } from './config'
import { DEFAULT_DATALAYER } from './datalayer'
import { setDataModels } from './services/redux/features/data-models.redux'
import { Analytics } from './services/analytics'
import { brandNames } from './resources/index'
import Routes from './components/Routes'
import * as baseModels from './resources/base/models'
import routes from './routes'
import { setBreakPoint } from './services/analytics/functions'
import './app.css'
import { getStorefrontData } from './services/redux/features/storefront.redux';
import { qs } from './services/routing';

const Loading = () => (
  <div className="u-text-center">
    <br />
    <br />
    <Spinner center />
    <br />
    <br />
  </div>
)

export const App = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storefrontData = useSelector(getStorefrontData);

  const [isReady, setReady] = useState(false)
  const [entryUrl] = useState(() => window.location.href)

  const pagesWithoutStorefrontId = useMemo(() => [routes.dataProtectionInfo], [])

  const [identificationId] = useState(() => qs(document.location.search, 'transaction-number'))
  const [storefrontId] = useState(() => {
    const id = qs(document.location.search, 'id')
    if (pagesWithoutStorefrontId.includes(window.location.pathname)) return

    const splittedPathName = `/${window.location.pathname?.split('/')?.[1]}`
    if (!identificationId && window.location.pathname !== '/error' && splittedPathName !== '/transactions') {
      dispatch(customActions.setErrorData({ url: window.location.href }));
      navigate(routes.errorPage)
    }

    return id
  })

  useEffect(() => {
    dispatch(setDataModels({ ...baseModels }))
    setReady(true)
  }, [dispatch])

  useEffect(() => {
    setBreakPoint()
  }, [storefrontData])

  useEffect(() => {
    Analytics.setDefaults(DEFAULT_DATALAYER)
  }, [])

  useEffect(() => {
    document.title = `${t('app:pageTitle')}`
    const description = document.querySelector("meta[name='description']");

    if (description) {
      description.setAttribute(
        'content',
        `${brandNames[getBrandName()]} ${t('app:pageTitle')}`
      );
    }
  }, [t])

  return (
    <>
      <div className="o-root">
        {isReady ? (
          <Routes
            storefrontId={storefrontId}
            entryUrl={entryUrl}
          />
        ) : (
          <Loading />
        )}
      </div>
    </>
  )
}

export default App

import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Spinner } from '@vwfs-bronson/bronson-react'
import getInitialValues from './initial-values'
import { RenderFieldset } from './fieldset'
import {
  ValidationSchemaPrivate,
  ValidationSchemaBusiness,
} from './validation-schema'
import { getDataModels } from '../../../services/redux/features/data-models.redux'
import { checkTls } from '../../../services/api/checkTls'
import { setTlsValid } from '../../../services/redux/features/custom.redux'
import { trackPage } from '../tracking'

interface PersonalDetailsProps {
  hidden?: boolean;
  editPage?: boolean;
  isBusiness?: boolean;
  isVtiGis?: boolean;
  setIsDirty: (isDirty: boolean) => void;
  getValues: (values: any) => void;
};

const PersonalDetails = forwardRef((props: PersonalDetailsProps, ref) => {
  const {
    hidden,
    editPage,
    isBusiness,
    isVtiGis,
    setIsDirty: setIsDirtyParent,
    getValues,
  } = props
  
  const dispatch = useDispatch();

  const dataModels = useSelector(getDataModels)
  const formState = useRef({ values: {} as any, isValid: false, dirty: false })
  const submitFunctionRef = useRef<() => void>()

  const [isDirty, setIsDirty] = useState(false)
  const [lastEmailSetted, setLastEmailSetted] = useState('')

  const regExEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,61}))$/;

  const initialValid = useMemo(() => {
    const schema = isBusiness ? ValidationSchemaBusiness : ValidationSchemaPrivate(isVtiGis)
    return schema.isValidSync(getInitialValues()) ?? false;
  }, [isBusiness, isVtiGis])

  useImperativeHandle(ref, () => ({
    submit: () => submitFunctionRef.current?.(),
    getCustomerData: () => {
      // eslint-disable-next-line no-nested-ternary
      return isBusiness
        ? { ...formState.current.values.customerData, dateOfBirth: undefined }
        : isVtiGis
          ? formState.current.values.customerData
          : {
              ...formState.current.values.customerData,
              address: {
                ...formState.current.values.customerData.address,
                countryCode: undefined,
              },
            }
    },
    getCompanyData: () => formState.current.values.companyData,
    isValid: () => formState.current.isValid,
    validate: () => formState.current.isValid,
  }))

  const onBlur = async () => {
    const email = formState?.current?.values?.customerData?.contactData?.email
    if (email && regExEmail.test(email) && email !== lastEmailSetted) {
      try {
        const result = await checkTls(email)
        dispatch(setTlsValid(result))
        setLastEmailSetted(email)
      } catch {}
    }
  }

  useEffect(() => {
    if (!hidden) {
      trackPage({ firstOpen: !editPage })
    }
  }, [hidden, editPage])

  useEffect(() => {
    setIsDirtyParent(isDirty)
  }, [isDirty, setIsDirtyParent])

  return (
    <>
      {Object.keys(dataModels).length ? (
        <Formik
          enableReinitialize
          initialValues={getInitialValues()}
          validationSchema={isBusiness ? ValidationSchemaBusiness : ValidationSchemaPrivate}
          isInitialValid={initialValid}
          onSubmit={() => {}}
        >
          {({
            values,
            handleSubmit,
            handleBlur,
            submitForm,
            setFieldValue,
            isValid,
            dirty
          }) => {
            getValues(values)
            formState.current = {
              values,
              isValid,
              dirty: dirty
            }
            setIsDirty(dirty)
            submitFunctionRef.current = submitForm

            const handleSubmitOwn = (e) => {
              handleSubmit(e)
            }

            const handleBlurOwn = (e) => {
              onBlur()
              handleBlur(e)
            }
            const handleChangeOwn = (ev) => {
              if (
                ev.target.name === 'customerData.contactData.mobilePhoneNumber'
              ) {
                setFieldValue(
                  'customerData.contactData.mobilePhoneNumber',
                  ev.target.value.replace(/\s+/g, '')
                )
              }
              if (ev.target.name === 'customerData.contactData.email') {
                setFieldValue(
                  'customerData.contactData.email',
                  ev.target.value.replace(/\s+/g, '')
                )
              }
            }

            return (
              <Form
                floatingLabel
                onSubmit={handleSubmitOwn}
                onBlur={handleBlurOwn}
                onChange={handleChangeOwn}
              >
                <RenderFieldset
                  values={values}
                  isBusiness={isBusiness}
                  isVtiGis={isVtiGis}
                />
              </Form>
            )
          }}
        </Formik>
      ) : (
        <div className="u-p-xlarge">
          <Spinner section />
        </div>
      )}
    </>
  )
})

export default PersonalDetails

import {
  FormField as CJFormField,
  DatePicker,
  ErrorMessage,
  Fieldset,
} from '@vwfs-bronson/bronson-react'
import React from 'react'
import { InfoTooltip } from '../InfoTooltip';
import { Field } from 'formik';
import get from 'lodash/get'

const normalizeDate = (value) => {
  if (!value) return "";
  // it is already in the proper format
  if (value.indexOf(".") !== -1) return value;

  return value.split("-").reverse().join(".");
};

interface Props {
  name: string
  labelText?: string
  infoIcon?: string
  className?: string
  testId?: string
  autoComplete?: string
  validate?: any
}

export function FormFieldDate({
  name,
  labelText,
  infoIcon,
  className,
  testId,
  autoComplete = 'off',
  validate,
}: Props) {
  return (
    <Fieldset.Row>
      <Field
        name={name}
        validate={validate}
      >
        {({
          field: { onChange, value, onBlur, name, ...field },
          form: { errors, touched },
          ...props
        }) => {
          return (
            <CJFormField
              testId={testId}
              className={className}
              labelForId={name}
              labelText={labelText}
              infoIcon={infoIcon && <InfoTooltip label={labelText} text={infoIcon} />}
              type="input"
              errorMessage={
                get(errors, name) && get(touched, name) ? (
                  <ErrorMessage id={name}>
                    {get(touched, name) && get(errors, name)}
                  </ErrorMessage>
                ) : (
                  ''
                )
              }
              noFloatingLabel
            >
              <DatePicker
                id={name}
                {...props}
                {...field}
                dateFormat='d.m.Y'
                placeholder='T.M.J'
                name={name}
                value={normalizeDate(value)}
                error={!!(get(touched, name) && get(errors, name))}
                inputProps={{
                  'aria-describedby': `error-${name}`,
                  autoComplete
                }}
                onChange={(value) => {
                  onChange({
                    target: {
                      name,
                      value,
                    },
                  });
                }}
                onBlur={(value) => {
                  onChange({
                    target: {
                      name,
                      value,
                    },
                  });
                  onBlur({ target: { name } });
                }}
              />
            </CJFormField>
          )
        }}
      </Field>
    </Fieldset.Row>
  )
}